




























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import DataTableResponsive, { cabecalhoInterface, acoesInterface } from '@/includes/auxForms/DataTableResponsive.vue'

import MensagemForm from '@/includes/auxForms/MensagemForm.vue'
import Loading from '@/includes/auxForms/Loading.vue'
import ErroWs from '@/includes/auxForms/ErroWs.vue'
import ConfirmDialog from '@/includes/auxForms/ConfirmDialog.vue'

import { THEME } from '@/plugins/vuetify'
import ClsQuadroListagemAlunos from './ClsQuadroListagemAlunos'
import { ClsCrudVue } from '../../components/crud/ClsCrudVue'
import { LayOutInterface } from '../../interfaces/LayOutInterface'
import Logger from '../../utils/Logger'
import { ChamadaAttributes } from '../../interfaces/backend/ModelAttributesInterface'
import { eventsInterface } from './ClsRelQuadroHorarios'
import ClsApollo from '../../utils/ClsApollo'
import Utils from '../../utils/Utils'
import { respostaPadraoInterface } from '../../interfaces/backend/padrao.interfaces'

@Component( {
  components: {
    MensagemForm,
    Loading,
    ErroWs,
    ConfirmDialog,
    DataTableResponsive
  }
} )

export default class ClsListagemAlunos extends Vue {

  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this, 'ChamadaAlunos' )

  private layout: LayOutInterface = this.clsCrudVue.layOutInicial

  @Prop( {
    default: false
  } )
  //@ts-ignore
  private value: boolean

  @Watch( 'value' )
  private onChangeExibir () {
    if ( this.value ) {
      this.getChamada()
    }
  }

  @Prop()
  //@ts-ignore
  private model: ChamadaAttributes & { nome?: string, matricula?: string }

  private rsChamada: ChamadaAttributes & { nome?: string, matricula?: string } = {}

  private THEME = THEME

  private btFechar (): void {
    this.$emit( 'btFechar' )
  }

  private getChamada () {

    let clsApollo: ClsApollo = new ClsApollo()

    this.rsChamada = { ...this.model }

    const sql = `
      query {
        listRaw(inputListRaw: {
          sql: "SELECT anotacao,presente FROM chamadas WHERE DATA = :DATA AND idCurso = :IDCURSO AND inicio = :HRINICIO AND idCliente = :IDCLIENTE;",
          replacements: {
            DATA: "${this.rsChamada.data}"
            IDCURSO: ${this.rsChamada.idCurso}
            HRINICIO: "${this.rsChamada.inicio}"
            IDCLIENTE: ${this.rsChamada.idCliente}
          }
        })
      }
    `

    return clsApollo
      .apolloQuery( this, sql, "listRaw", false, "no-cache" )
      .then( ( resultQuery: Array<ChamadaAttributes> ) => {
        if ( resultQuery.length > 0 ) {
          this.rsChamada.anotacao = resultQuery[0].anotacao
          this.rsChamada.presente = resultQuery[0].presente ? true : false
        }
      } )

  }

  private btConfirmarChamada (): void {

    let clsApollo: ClsApollo = new ClsApollo()

    let tmpModel: ChamadaAttributes & { nome?: string, matricula?: string } = { ...this.rsChamada }

    delete tmpModel.nome
    delete tmpModel.matricula

    const sql: string = `
      mutation {
        atualizarChamada(rsChamada: ${Utils.ConverterEmGql( tmpModel )}) {
          ok
          mensagem
        }
      }
    `

    Logger.logar( sql, 'sql....' )
    clsApollo.apolloMutation( this, sql, 'atualizarChamada', true ).then( ( rs: respostaPadraoInterface ) => {
        this.$emit( 'btFechar' )
    } )

  }

}
