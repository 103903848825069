import Validadores from "@/utils/Validadores";

export interface TypeTiposContatoInterface {
  descricao: string
  mascara: string | Array<string>
  validador: object
}

export const TiposContatoType: Array<TypeTiposContatoInterface> = [
  {
    descricao: 'Fone Comercial',
    mascara: ['(##) ####-####', '(##) #####-####'],
    validador: Validadores.foneFixoCel
  },
  {
    descricao: 'Fone Residencial',
    mascara: ['(##) ####-####', '(##) #####-####'],
    validador: Validadores.foneFixoCel
  },
  {
    descricao: 'Celular',
    mascara: ['(##) ####-####', '(##) #####-####'],
    validador: Validadores.foneCelular
  },
  {
    descricao: 'Celular e Whats',
    mascara: ['(##) ####-####', '(##) #####-####'],
    validador: Validadores.foneCelular
  },
  {
    descricao: 'WhatsApp',
    mascara: ['(##) ####-####', '(##) #####-####'],
    validador: Validadores.foneCelular
  },
  {
    descricao: 'e-mail',
    mascara: '',
    validador: Validadores.email
  },
  {
    descricao: 'Endereço',
    mascara: '',
    validador: Validadores.naoVazio
  },
  {
    descricao: 'Facebook',
    mascara: '',
    validador: Validadores.naoVazio
  },
  {
    descricao: 'LinkEdin',
    mascara: '',
    validador: Validadores.naoVazio
  },
  {
    descricao: 'Instagram',
    mascara: '',
    validador: Validadores.naoVazio
  }]