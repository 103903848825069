// export const SENHAPADRAOUSUARIONOVO: string = '4Doctor'

export const TIMEOUTWEBSERVICE: number = 15000

export const ICONES = {
  Home: { icone: 'mdi-home' },
  MenuApp: { icone: 'mdi-apps' },
  MenuGrupos: { icone: 'mdi-account' },
  MenuUsuario: { icone: 'mdi-account-circle' },
  MenuCliente: { icone: 'mdi-account-tie' },
  MenuOportunidade: { icone: 'mdi-account-cash' },
  AlterarPropriaSenha: { icone: 'mdi-lock-reset' },
  MenuMinhaConta: { icone: 'mdi-account' },
  Logout: { icone: 'mdi-logout' },
  VisibilityOff: { icone: 'mdi-eye-off' },
  VisibilityOn: { icone: 'mdi-eye' },
  FullScreen: { icone: 'mdi-cellphone-link' },
  FecharJanela: { icone: 'mdi-close' },
  Chamada: { icone: 'mdi-human-greeting' },
  Contato: { icone: 'mdi-card-account-phone' },
  CrudConfirmarEdicao: { icone: 'mdi-check' },
  CrudConfirmarInclusao: { icone: 'mdi-check' },
  CrudCancel: { icone: 'mdi-close-circle' },
  CrudIncluir: { icone: 'mdi-plus' },
  CrudEditar: { icone: 'mdi-pencil' },
  CrudExcluir: { icone: 'mdi-delete' },
  Pesquisar: { icone: 'mdi-magnify' },
  CrudCopy: { icone: 'mdi-content-copy' },
  CrudPaste: { icone: 'mdi-content-paste' },
  CrudConfirmarExclusao: { icone: 'mdi-check' },
  CrudAlterarSenha: { icone: 'mdi-key' },
  ClienteAcompanhamento: { icone: 'mdi-account-details' },
  ClienteEditar: { icone: 'mdi-account-edit' },
  ClienteUsuarios: { icone: 'mdi-account-supervisor' },
  ClienteAgenda: { icone: 'mdi-calendar-range' },
  ClientePesquisa: { icone: 'mdi-magnify' },
  RelQuadroHorario: { icone: 'mdi-timetable' },
}