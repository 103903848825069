var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-xs-center"},[_c('frm-chamada',{attrs:{"model":_vm.modelChamada},on:{"btFechar":_vm.fecharChamada},model:{value:(_vm.exibirChamada),callback:function ($$v) {_vm.exibirChamada=$$v},expression:"exibirChamada"}}),_c('frm-exibir-contato',{attrs:{"idCliente":_vm.idCliente},on:{"btFechar":_vm.fecharContato},model:{value:(_vm.exibirContato),callback:function ($$v) {_vm.exibirContato=$$v},expression:"exibirContato"}}),_c('v-dialog',{staticClass:"pa-5",attrs:{"overlay-opacity":"0","transition":"scale-transition","persistent":"","max-width":"1200px"},model:{value:(_vm.exibir),callback:function ($$v) {_vm.exibir=$$v},expression:"exibir"}},[_c('v-card',{style:({backgroundColor: _vm.THEME.mensagens.corFundo, color:_vm.THEME.mensagens.corFonte})},[_c('v-card-title',{style:({color:_vm.THEME.mensagens.corFonte})},[_vm._v("Atenção:")]),_c('v-card-text',{staticClass:"pa-5",style:({backgroundColor: _vm.THEME.mensagens.cor, color:_vm.THEME.mensagens.corFonte})},[_c('data-table-responsive',{attrs:{"campoChave":"idCliente","titulo":_vm.clsQuadroListagemAlunos.titulo,"colunas":_vm.clsQuadroListagemAlunos.cabecalho,"itens":_vm.clsQuadroListagemAlunos.itens,"acoes":[{
                icone: 'Chamada',
                evento: 'btChamada',
                tooltip: 'Chamada'
              },
              {
                icone: 'Contato',
                evento: 'btContato',
                tooltip: 'Contato'
              }
              ]},on:{"btChamada":_vm.btChamada,"btContato":_vm.btContato}})],1),_c('v-card-actions',{style:({color:_vm.THEME.mensagens.corFonte})},[_c('v-spacer'),_c('v-btn',{style:({backgroundColor: _vm.THEME.mensagens.corFundo, color:_vm.THEME.mensagens.corFonte}),on:{"click":_vm.btFecharJanela}},[_vm._v("Fechar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }