























































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import MensagemForm from '@/includes/auxForms/MensagemForm.vue'
import Loading from '@/includes/auxForms/Loading.vue'
import ErroWs from '@/includes/auxForms/ErroWs.vue'
import ConfirmDialog from '@/includes/auxForms/ConfirmDialog.vue'
import { LayOutInterface } from '@/interfaces/LayOutInterface'
import { ClsCrudVue } from '@/components/crud/ClsCrudVue'
import { ClsRelQuadroHorarios, eventsInterface } from './ClsRelQuadroHorarios'

import QuadroListagemAlunos from './QuadroListagemAlunos.vue'

import moment from 'moment'
import { DTFORMAT } from '../../includes/config/DataTypesAplicacao'
import Logger from '../../utils/Logger'

@Component( {
  components: {
    MensagemForm,
    Loading,
    ErroWs,
    ConfirmDialog,
    QuadroListagemAlunos
  }
} )

export default class Crud extends Vue {

  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this, 'MeusClientes' )

  private layout: LayOutInterface = this.clsCrudVue.layOutInicial

  private clsRelQuadroHorarios: ClsRelQuadroHorarios = new ClsRelQuadroHorarios( this )

  private dataAtual: string = this.clsRelQuadroHorarios.dataBase

  private mounted () {
    //@ts-ignore
    this.$refs.quadroHorario.scrollToTime( '07:00' )
    // this.clsRelQuadroHorarios.refreshQuadro()
  }

  private tipoQuadro: string = ''
  private tipoView: string = 'week'

  private btChangeTipoQuadro (): void {
    this.clsRelQuadroHorarios.refreshQuadro( this.tipoQuadro )
  }

  private getEventColor ( event: eventsInterface ) {
    return event.color
  }

  private btProximo () {
    //if ( this.tipoView == 'day' && this.dataAtual < this.clsRelQuadroHorarios.finalSemana ) {
    //@ts-ignore
    this.$refs.quadroHorario.next()
    //}
  }

  private btAnterior () {

    //if ( this.tipoView == 'day' && this.dataAtual > this.clsRelQuadroHorarios.inicioSemana ) {
    //this.dataAtual = '2020-04-03'
    //@ts-ignore
    this.$refs.quadroHorario.prev()
    // }
  }

  private exibirAlunos: boolean = false
  private eventoExibir: eventsInterface = {
    idCurso: 0,
    name: '',
    start: '',
    end: '',
    color: ''
  }

  private btExibirAlunos ( evento: any ) {
    this.eventoExibir = evento
    this.exibirAlunos = true
  }

  private btFecharExibirAlunos () {
    this.exibirAlunos = false
  }

}
