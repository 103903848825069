import ClsApollo from "@/utils/ClsApollo";
import Utils from "@/utils/Utils";
import * as Bluebird from "bluebird";
import { ClienteAttributes, ClienteContatoAttributes, ClienteUsuarioAttributes, ClienteEtiquetaAttributes, ClienteAcompanhamentoAttributes, ClienteCursoAttributes } from "@/interfaces/backend/ModelAttributesInterface";

export interface modelClientePesquisaInterface {
  terminoAgenda: string
  inicioAgenda: string
  terminoCadastro: string
  inicioCadastro: string
  terminoAcompanhamento: string
  inicioAcompanhamento: string
  rsUsuarios: Array<number>
  rsInstrutores: Array<number>
  rsStatus: Array<number>
  pesquisa: string
  rsEtiquetas: Array<number>
  diasLetivos: Array<number>
  inicioAula: string
  terminoAula: string
  inicioFalta: string
  terminoFalta: string
}

const SQLPESQUISA: string = `SELECT DISTINCT cl.idCliente, matricula, nome, dataNascimento, bairro, cidade FROM clientes AS cl LEFT JOIN clientescontatos AS cc ON cl.idCliente = cc.idCliente LEFT JOIN agendas AS ag ON cl.idCliente = ag.idCliente LEFT JOIN clientesusuarios AS us ON cl.idCliente = us.idCliente LEFT JOIN clientesacompanhamentos AS ac ON cl.idCliente = ac.idCliente LEFT JOIN clientesetiquetas AS ce ON cl.idCliente = ce.idCliente LEFT JOIN clientescursos AS ccur ON cl.idCliente = ccur.idCliente LEFT JOIN chamadas AS ch ON cl.idCliente = ch.idCliente AND NOT ch.presente`

const SQLCLIENTES_SEM_USUARIO: string = 'SELECT DISTINCT cl.idCliente, matricula, nome, dataNascimento, bairro, cidade FROM clientes AS cl LEFT JOIN clientesusuarios AS us ON cl.idCliente = us.idCliente WHERE us.idCliente IS NULL;'

export class ClsClientePesquisa {

  private self: any

  public constructor( self: any ) {
    this.self = self
  }

  public pesquisarClienteSemUsuario (): Bluebird<Array<any>> {
    let clsApollo: ClsApollo = new ClsApollo()

    const sql = `

    query {
      listRaw(inputListRaw: {
        sql: "${SQLCLIENTES_SEM_USUARIO}"
      })
    }
    `

    return clsApollo
      .apolloQuery( this.self, sql, "listRaw" )
      .then( ( resultQuery: any ) => {
        return resultQuery
      } )

  }

  public pesquisarCliente ( model: modelClientePesquisaInterface ): Bluebird<Array<any>> {
    let clsApollo: ClsApollo = new ClsApollo()

    const sql = `

    query {
      listRaw(inputListRaw: {
        sql: "${this.montarSQL( model )}",
        replacements: {
          TERMINOAGENDA: "${Utils.converterDataParaBanco( model.terminoAgenda )}"
          INICIOAGENDA: "${Utils.converterDataParaBanco( model.inicioAgenda )}"
          TERMINOCADASTRO: "${Utils.converterDataParaBanco( model.terminoCadastro )}"
          INICIOCADASTRO: "${Utils.converterDataParaBanco( model.inicioCadastro )}"
          TERMINOACOMPANHAMENTO: "${Utils.converterDataParaBanco( model.terminoAcompanhamento )}"
          INICIOACOMPANHAMENTO: "${Utils.converterDataParaBanco( model.inicioAcompanhamento )}"
          RSUSUARIOS: [${model.rsUsuarios}]
          RSSTATUS: [${model.rsStatus}]
          PESQUISA: "%${model.pesquisa}%"
          RSETIQUETAS: [${model.rsEtiquetas}]
          RSDIASLETIVOS: [${model.diasLetivos}]
          RSINSTRUTORES: [${model.rsInstrutores}]
          HRINICIO: "${model.inicioAula}"
          HRTERMINO: "${model.terminoAula}"
          INICIOFALTA: "${Utils.converterDataParaBanco( model.inicioFalta )}"
          TERMINOFALTA:  "${Utils.converterDataParaBanco( model.terminoFalta )}"
        }
      })
    }
    `

    return clsApollo
      .apolloQuery( this.self, sql, "listRaw" )
      .then( ( resultQuery: any ) => {
        return resultQuery
      } )
  }

  private montarSQL ( model: modelClientePesquisaInterface ): string {
    let where: string = ""

    if ( model.rsStatus.length > 0 ) {
      where = where.concat( " AND cl.idStatus IN (:RSSTATUS)" )
    }

    if ( model.rsUsuarios.length > 0 ) {
      where = where.concat( " AND us.idUsuario IN (:RSUSUARIOS)" )
    }

    if ( model.rsEtiquetas.length > 0 ) {
      where = where.concat( " AND ce.idEtiqueta IN (:RSETIQUETAS)" )
    }

    if ( model.diasLetivos.length > 0 ) {
      where = where.concat( " AND ccur.diaLetivo IN (:RSDIASLETIVOS)" )
    }

    if ( model.pesquisa.length > 0 ) {
      where = where.concat( " AND (cl.matricula LIKE :PESQUISA or cl.nome LIKE :PESQUISA or cc.descricao LIKE :PESQUISA)" )
    }

    // Data de Acompanhamento
    if ( model.inicioAgenda.length == 10 ) {
      where = where.concat( " AND ag.data >= :INICIOAGENDA" )
    }

    if ( model.terminoAgenda.length == 10 ) {
      where = where.concat( " AND ag.data <= :TERMINOAGENDA" )
    }

    // Data de Cadastro
    if ( model.inicioCadastro.length == 10 ) {
      where = where.concat( " AND cl.createdAt >= :INICIOCADASTRO" )
    }

    if ( model.terminoCadastro.length == 10 ) {
      where = where.concat( " AND cl.createdAt <= :TERMINOCADASTRO" )
    }

    // Acompanhamento
    if ( model.inicioAcompanhamento.length == 10 ) {
      where = where.concat( " AND ac.createdAt >= :INICIOACOMPANHAMENTO" )
    }

    if ( model.terminoAcompanhamento.length == 10 ) {
      where = where.concat( " AND ac.createdAt <= :TERMINOACOMPANHAMENTO" )
    }

    // Periodo de Falta
    if ( model.inicioFalta.length == 10 ) {
      where = where.concat( " AND ch.data >= :INICIOFALTA" )
    }

    if ( model.terminoFalta.length == 10 ) {
      where = where.concat( " AND ch.data <= :TERMINOFALTA" )
    }

    // Se for chamada, inclui instrutor faltas, senão inclui em clientes cursos se houver....
    if ( model.inicioFalta.length == 10 && model.terminoFalta.length == 10 && model.rsInstrutores.length > 0 ) {
      where = where.concat( " AND ch.idInstrutor IN (:RSINSTRUTORES)" )
    } else {
      if ( model.rsInstrutores.length > 0 ) {
        where = where.concat( " AND ccur.idUsuario IN (:RSINSTRUTORES)" )
      }
    }

    // Inicio e Término
    if ( model.inicioAula.length == 5 ) {
      where = where.concat( " AND ccur.inicio >= :HRINICIO" )
    }

    if ( model.terminoAula.length == 5 ) {
      where = where.concat( " AND ccur.termino <= :HRTERMINO" )
    }

    if ( where.length > 0 ) {
      where = " WHERE ".concat( where.substr( 4 ).trim() )
    }

    // TODO - Avisar Usuário do Limite da Pesquisa

    let retorno: string = SQLPESQUISA.concat( where ).concat( " LIMIT 100;" )

    return retorno

  }

  // Pesquisa de Clientes

  private rsTmpCliente: ClienteAttributes = {}
  private rsTmpClienteContatos: Array<ClienteContatoAttributes> = []
  private rsTmpClienteCursos: Array<ClienteCursoAttributes> = []
  private rsTmpClienteUsuarios: Array<ClienteUsuarioAttributes> = []
  private rsTmpClienteEtiquetas: Array<ClienteEtiquetaAttributes> = []
  private rsTmpClienteAcompanhamentos: Array<ClienteAcompanhamentoAttributes> = []

  public get rsCliente (): ClienteAttributes {
    return this.rsTmpCliente
  }

  public get rsClienteContatos (): Array<ClienteContatoAttributes> {
    return this.rsTmpClienteContatos
  }

  public get rsClienteCursos (): Array<ClienteAcompanhamentoAttributes> {
    return this.rsTmpClienteCursos
  }

  public get rsClienteUsuarios (): Array<ClienteUsuarioAttributes> {
    return this.rsTmpClienteUsuarios
  }

  public get rsClienteEtiquetas (): Array<ClienteEtiquetaAttributes> {
    return this.rsTmpClienteEtiquetas
  }

  public get rsClienteAcompanhamentos (): Array<ClienteAcompanhamentoAttributes> {
    return this.rsTmpClienteAcompanhamentos
  }

  public refreshCliente ( idCliente: number ): Bluebird<any> {

    let clsApollo: ClsApollo = new ClsApollo()

    const sqlClienteEtiquetas = `
      query {
        list(inputCrud: {
          tabela: "ClienteEtiqueta"
          list: {
            attributes: ["idEtiqueta"]
            where: {
              idCliente: ${idCliente}
            }
          }
        })
      }
    `

    const sqlClienteAcompanhamentos = `

      query {
        list(inputCrud: {
          tabela: "ClienteAcompanhamento"
          list: {
            attributes: ["idAcompanhamento","descricao","idCanal","idUsuario","createdAt"]
            where: {
              idCliente: ${idCliente}
            }
          }
        })
      }

    `

    const sqlClienteContatos = `

      query {
        list(inputCrud: {
          tabela: "ClienteContato"
          list: {
            attributes: ["descricao","tipo"]
            where: {
              idCliente: ${idCliente}
            }
          }
        })
      }

    `

    const sqlClienteCursos = `

      query {
        list(inputCrud: {
          tabela: "ClienteCurso"
          list: {
            attributes: ["tipoAula","idCurso", "idUsuario", "diaLetivo", "inicio", "termino"]
            where: {
              idCliente: ${idCliente}
            }
          }
        })
      }

    `

    const sqlClienteUsuarios = `

      query {
        list(inputCrud: {
          tabela: "ClienteUsuario"
          list: {
            attributes: ["idUsuario"]
            where: {
              idCliente: ${idCliente}
            }
          }
        })
      }

    `

    const sqlCliente = `

      query {
        getById(inputCrud: {
          tabela: "Cliente"
          campoChave: "idCliente"
          getById: ${idCliente}
        })
      }    

    `

    this.self.layout.exibirLoading = true

    return clsApollo
      .apolloQuery( this.self, sqlCliente, 'getById', false, "no-cache", false )
      .then( ( rsCliente: ClienteAttributes ) => {
        rsCliente.dataNascimento = Utils.converterDataParaUsuario( rsCliente.dataNascimento )

        this.rsTmpCliente = { ...rsCliente }

        return clsApollo.apolloQuery( this.self, sqlClienteContatos, 'list', false, "no-cache", false ).then( rsClientesContatos => {
          this.rsTmpClienteContatos = rsClientesContatos

          return clsApollo.apolloQuery( this.self, sqlClienteCursos, 'list', false, "no-cache", false ).then( rsClientesCursos => {
            this.rsTmpClienteCursos = rsClientesCursos

            return clsApollo.apolloQuery( this.self, sqlClienteUsuarios, 'list', false, "no-cache", false ).then( rsClienteUsuarios => {

              this.rsTmpClienteUsuarios = rsClienteUsuarios.map( ( v: ClienteUsuarioAttributes ) => v.idUsuario )

              return clsApollo.apolloQuery( this.self, sqlClienteAcompanhamentos, 'list', false, "no-cache", false ).then( rsClienteAcompanhamentos => {

                this.rsTmpClienteAcompanhamentos = [...rsClienteAcompanhamentos]

                return clsApollo.apolloQuery( this.self, sqlClienteEtiquetas, 'list', false, "no-cache", false ).then( rsClienteEtiquetas => {

                  this.rsTmpClienteEtiquetas = rsClienteEtiquetas.map( ( v: ClienteEtiquetaAttributes ) => v.idEtiqueta )

                  this.self.layout.exibirLoading = false
                } )

              } )

            } )

            // rsClienteUsuarios.map( ( v: any ) => { return _.find( this.clsCrudVue.state.cadastros.rsUsuarios, { 'idUsuario': v.idUsuario } ) } )

            // this.self.layout.exibirLoading = false
          } )

        } )

      } )

  }
}