



































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import DataTableResponsive, { cabecalhoInterface, acoesInterface } from '@/includes/auxForms/DataTableResponsive.vue'

import MensagemForm from '@/includes/auxForms/MensagemForm.vue'
import Loading from '@/includes/auxForms/Loading.vue'
import ErroWs from '@/includes/auxForms/ErroWs.vue'
import ConfirmDialog from '@/includes/auxForms/ConfirmDialog.vue'

import { THEME } from '@/plugins/vuetify'
import ClsQuadroListagemAlunos, { rsListagemGeralInterface } from './ClsQuadroListagemAlunos'
import { ClsCrudVue } from '../../components/crud/ClsCrudVue'
import { LayOutInterface } from '../../interfaces/LayOutInterface'
import Logger from '../../utils/Logger'
import { ChamadaAttributes } from '../../interfaces/backend/ModelAttributesInterface'
import { eventsInterface, rsQuadroInterface } from './ClsRelQuadroHorarios'

import frmChamada from './frmChamada.vue'
import frmExibirContato from './frmExibirContato.vue'

@Component( {
  components: {
    MensagemForm,
    Loading,
    ErroWs,
    ConfirmDialog,
    DataTableResponsive,
    frmChamada,
    frmExibirContato
  }
} )

export default class ClsListagemAlunos extends Vue {

  // private clsCrudVue: ClsCrudVue = new ClsCrudVue( this, 'MeusClientes' )

  // private layout: LayOutInterface = this.clsCrudVue.layOutInicial

  @Prop( {
    default: false
  } )
  //@ts-ignore
  private exibir: boolean

  @Watch( 'exibir' )
  private onChangeExibir () {
    this.refresh()
  }

  @Prop()
  //@ts-ignore
  private tipoQuadro: string

  @Prop()
  //@ts-ignore
  private evento: any

  private btFecharJanela (): void {
    this.$emit( 'btFechar' )
  }

  private clsQuadroListagemAlunos: ClsQuadroListagemAlunos = new ClsQuadroListagemAlunos( this )

  private THEME = THEME

  private refresh () {
    if ( this.exibir ) {
      this.clsQuadroListagemAlunos.refresh( this.evento, this.tipoQuadro ).then( rs => {
      } )
    }
  }

  //@ts-ignore
  private modelChamada: ChamadaAttributes & { nome?: string, matricula?: string } = {}

  private exibirChamada: boolean = false
  private exibirContato: boolean = false
  private idCliente: number = 0

  private btChamada ( evento: rsListagemGeralInterface ) {

    this.modelChamada = {
      anotacao: '',
      data: this.evento.event.start.substring( 0, 10 ),
      idCliente: evento.idCliente,
      idCurso: evento.idCurso,
      presente: false,
      inicio: this.evento.event.start.substring( 11 ),
      termino: this.evento.event.end.substring( 11 ),
      idInstrutor: evento.idInstrutor,
      nome: evento.nome,
      matricula: evento.matricula,
    }

    this.exibirChamada = true

  }

  private fecharChamada () {
    this.exibirChamada = false
  }

  private btContato ( evento: rsListagemGeralInterface ): void {
    this.idCliente = evento.idCliente
    this.exibirContato = true
  }

  private fecharContato () {
    this.exibirContato = false
  }

}
