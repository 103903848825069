
























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { ClsCrudVue } from '../crud/ClsCrudVue';

import ClsApollo from '../../utils/ClsApollo';
import gql from 'graphql-tag';

@Component
export default class MenuRelatorios extends Vue {

  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this )

  private btMenuRelatorios (): void {
    this.$emit( 'btMenuRelatorios' )
  }

  private relatorios: Array<any> = [
    {
      idRelatorio: 1,
      descricao: 'Quadro de Horários',
      permissao: this.clsCrudVue.PERMISSOES.CLIENTEACOMPANHAMENTO.PERMISSAO.CREATE,
      icone: 'RelQuadroHorario',
      link: '/RelQuadroHorario'
    }
  ]


}
