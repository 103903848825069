





















import { Component, Prop, Vue } from 'vue-property-decorator'

import { LayOutInterface } from '@/interfaces/LayOutInterface'

import btCrudPesquisa from '@/includes/components/btCrudPesquisa.vue'

import _ from 'lodash'
import { ClsCrudVue } from '@/components/crud/ClsCrudVue';

export interface cabecalhoCrudPesquisaInterface {
  text: string,
  align: string,
  value: string,
  sortable: boolean
}

@Component( {
  components: {
    btCrudPesquisa
  }
} )

export default class CrudPesquisa extends Vue {

  @Prop()
  //@ts-ignore
  private itens: Array<any>

  @Prop()
  //@ts-ignore
  private layout: LayOutInterface

  @Prop()
  //@ts-ignore
  private campoChave: string

  @Prop()
  //@ts-ignore
  private campos: Array<cabecalhoCrudPesquisaInterface>

  @Prop({
    default: false
  })
  //@ts-ignore
  private HideDefaultFooter: boolean

  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this )

  // { text: 'Descrição', align: 'left', value: 'descricao', sortable: true },

  private get cabecalhos (): Array<cabecalhoCrudPesquisaInterface> {
    let retorno: Array<cabecalhoCrudPesquisaInterface> = [...this.campos]
    retorno.push( { text: 'Ações', align: 'left', value: '', sortable: false } )
    return retorno
  }

  private excluirCampoCodigo ( item: any ): any {
    return item
  }

}
