






































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

// @ts-ignore
import { mask } from 'vue-the-mask'
import { ClienteAttributes, ClienteContatoAttributes, ClienteUsuarioAttributes, ClienteEtiquetaAttributes, ClienteCursoAttributes } from '../../../interfaces/backend/ModelAttributesInterface'

import Validadores from '@/utils/Validadores'
import { LayOutInterface } from '../../../interfaces/LayOutInterface'
import { ClsCrudVue } from '../ClsCrudVue'

import CrudClienteContato from './clientecontato.crud.vue'
import Logger from '../../../utils/Logger'

import ClienteCrudHorario from './cliente.crud.horario.vue'

@Component( {
  directives: {
    mask
  },
  components: {
    CrudClienteContato,
    ClienteCrudHorario
  }
} )
export default class Crud extends Vue {

  @Prop()
  //@ts-ignore
  private model: ClienteAttributes

  @Prop()
  //@ts-ignore
  private layout: LayOutInterface

  @Prop()
  //@ts-ignore
  private clsCrudVue: ClsCrudVue

  @Prop()
  //@ts-ignore
  private rsClienteContatos: Array<ClienteContatoAttributes>

  @Prop()
  //@ts-ignore
  private rsClienteCursos: Array<ClienteCursoAttributes>

  @Prop()
  //@ts-ignore
  private rsClienteUsuarios: Array<ClienteUsuarioAttributes>

  @Watch( 'rsClienteUsuarios' )
  private onChangeRsClienteUsuarios ( newValue: Array<ClienteContatoAttributes> ) {
    this.rsTmpClienteUsuarios = newValue
  }

  @Prop()
  //@ts-ignore
  private rsClienteEtiquetas: Array<ClienteUsuarioAttributes>

  @Watch( 'rsClienteEtiquetas' )
  private onChangeRsClienteEtiquetas ( newValue: Array<ClienteEtiquetaAttributes> ) {
    this.rsTmpClienteEtiquetas = newValue
  }

  private rsTmpClienteUsuarios: Array<ClienteUsuarioAttributes> = []
  private rsTmpClienteEtiquetas: Array<ClienteEtiquetaAttributes> = []

  private get Validadores () {
    return Validadores
  }

  private KeyRefreshComboBox: number = 0

  private searchBairro: string = ''

  @Watch( 'searchBairro' )
  private onChangeSearchBairro ( newValue: string, oldValue: string ) {
    this.model.bairro = newValue
  }

  private searchCidade: string = ''

  @Watch( 'searchCidade' )
  private onChangeSearchCidade ( newValue: string, oldValue: string ) {
    this.model.cidade = newValue
  }

  private onChangeRsTmpClienteContatos ( contatos: Array<ClienteContatoAttributes> ): void {
    this.$emit( 'onChangeRsTmpClienteContatos', contatos )
  }

  private onChangeRsTmpClienteUsuarios ( rsClientesUsuarios: Array<ClienteUsuarioAttributes> ): void {
    this.$emit( 'onChangeRsTmpClienteUsuarios', rsClientesUsuarios )
  }

  private onChangeRsTmpClienteEtiquetas ( rsClienteEtiquetas: Array<ClienteEtiquetaAttributes> ): void {
    this.$emit( 'onChangeRsTmpClienteEtiquetas', rsClienteEtiquetas )
  }

  private onChangeRsTmpClienteCursos ( rsClienteCursos: Array<ClienteCursoAttributes> ): void {
    this.$emit( 'onChangeRsTmpClienteCursos', rsClienteCursos )
  }


}

