












import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ClsCrudVue } from '../crud/ClsCrudVue';
import store from '../../store';
import { THEME } from '../../plugins/vuetify';
import Logger from '../../utils/Logger';

import ViewAplicacoes from './ViewAplicacoes.vue'

@Component( {
  components: {
    ViewAplicacoes
  }
} )
export default class MenuRelatorios extends Vue {
  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this )

  private THEME = THEME

}
