



































import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import Logger from "../../utils/Logger"
import moment from "moment"
import { DTFORMAT } from "../config/DataTypesAplicacao"

import Validadores from '@/utils/Validadores'
import Utils from "../../utils/Utils"

@Component
export default class ClsInputDatePicker extends Vue {

  private txtData: string = ''
  private exibirDatePicker: boolean = false

  @Prop()
  //@ts-ignore
  private value: string

  @Prop( {
    default: false
  } )
  //@ts-ignore
  private disabled: boolean

  @Prop( {
    default: false
  } )
  //@ts-ignore
  private outlined: boolean

  @Prop( {
    default: null
  } )
  //@ts-ignore
  private max: string

  @Prop( {
    default: null
  } )
  //@ts-ignore
  private min: string

  @Prop( {
    default: false
  } )
  //@ts-ignore
  private defaultDate: boolean

  @Prop( {
    default: ''
  } )
  //@ts-ignore
  private label: string

  private txtDatePicker: string | null = moment().format( DTFORMAT.BANCO )

  private get Validadores () {
    return Validadores
  }

  private mounted (): void {
    let tmpData: string = this.value

    if ( tmpData.length != 10 || !moment( tmpData, DTFORMAT.USUARIO ).isValid() ) {
      if ( this.defaultDate ) {
        tmpData = moment().format( DTFORMAT.USUARIO )
      } else {
        tmpData = ''
      }
    }

    this.txtData = tmpData
  }

  private limparData (): void {
    this.txtDatePicker = null
    this.txtData = ''
    this.$emit( 'input', this.txtData )
  }

  private onChangeTxtData () {
    if ( Utils.dataValida( this.txtData ) ) {
      this.txtDatePicker = Utils.converterDataParaBanco( this.txtData )
    }
    this.$emit( 'input', this.txtData )
  }

  private onChangeTxtDatePicker () {
    this.txtData = moment( <string>this.txtDatePicker, DTFORMAT.BANCO ).format( DTFORMAT.USUARIO )
    this.$emit( 'input', this.txtData )
  }

}
