




























import { Component, Prop, Vue } from 'vue-property-decorator'
import { LayOutInterface } from '@/interfaces/LayOutInterface';

import { THEME } from '@/plugins/vuetify'

@Component
export default class Loading extends Vue {
  @Prop()
  private layout!: LayOutInterface

  private THEME = THEME

  private exibir: boolean = true

}
