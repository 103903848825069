







































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { LayOutInterface } from '../../../interfaces/LayOutInterface'
import { ClsCrudVue } from '../ClsCrudVue'
import { TiposContatoType } from '../../../includes/config/typeTiposContato'

import ClienteExibirAcompanhamento from './cliente.exibir.acompanhamento.vue'
import ClienteExibirFrequencia from './cliente.exibir.frequencia.vue'

import crudPesquisa from '@/includes/auxForms/CrudPesquisa.vue'

// @ts-ignore
import { mask } from 'vue-the-mask'
import Logger from '../../../utils/Logger'
import ClsApollo from '../../../utils/ClsApollo'
import moment from 'moment'
import { DTFORMAT } from '../../../includes/config/DataTypesAplicacao'
import Utils from '../../../utils/Utils'

import _ from 'lodash'
import { ClienteAcompanhamentoAttributes } from '../../../interfaces/backend/ModelAttributesInterface'

const enum tipoCampoAlteracaoType {
  STATUS = 0,
  ETIQUETAS = 1,
  USUARIO = 2
}

@Component( {
  directives: {
    mask
  },
  components: {
    crudPesquisa,
    ClienteExibirAcompanhamento,
    ClienteExibirFrequencia
  }
} )
export default class Crud extends Vue {

  @Prop()
  //@ts-ignore
  private clsCrudVue: ClsCrudVue

  @Prop()
  //@ts-ignore
  private layout: LayOutInterface

  @Prop()
  //@ts-ignore
  private thisPai: any

  @Prop( {
    default: 0
  } )
  //@ts-ignore
  private idCliente: number

  @Prop()
  //@ts-ignore
  private rsClienteEtiquetas: Array<number>

  @Prop()
  //@ts-ignore
  private rsClienteUsuarios: Array<number>

  @Prop()
  //@ts-ignore
  private idStatus: number

  private rsTmpClienteEtiquetas: Array<number> = []
  private rsTmpClienteUsuarios: Array<number> = []
  private idTmpStatus: number = 0

  private keyRefreshFields: number = 1

  private keyRefresh: number = 1

  private idCanal: number = 0
  private descricaoAcompanhamento: string = ''
  private alterarStatus: boolean = false
  private alterarEtiquetas: boolean = false

  @Watch( 'rsClienteEtiquetas' )
  private onChangerRsClienteEtiquetas () {
    this.rsTmpClienteEtiquetas = [...this.rsClienteEtiquetas]
  }

  @Watch( 'rsClienteUsuarios' )
  private onChangerRsClienteUsuarios () {
    this.rsTmpClienteUsuarios = [...this.rsClienteUsuarios]
  }

  @Watch( 'idStatus' )
  private onChangeridStatus () {
    this.idTmpStatus = this.idStatus
  }

  private btIncluirAcompanhamento (): void {

    this.ChecarAlteracao( tipoCampoAlteracaoType.STATUS, this.idStatus, this.idTmpStatus )
    this.ChecarAlteracao( tipoCampoAlteracaoType.ETIQUETAS, this.rsClienteEtiquetas, this.rsTmpClienteEtiquetas )
    this.ChecarAlteracao( tipoCampoAlteracaoType.USUARIO, this.rsClienteUsuarios, this.rsTmpClienteUsuarios )

    let clsApollo: ClsApollo = new ClsApollo()

    let model: ClienteAcompanhamentoAttributes = {
      descricao: this.descricaoAcompanhamento,
      idCanal: this.idCanal
    }

    const sqlIncluir = `
      mutation {
        atualizarClienteAcompanhamento(
          idCliente: ${this.idCliente} 
          rsClienteUsuarios: [${this.rsTmpClienteUsuarios}]
          rsClienteEtiquetas: [${this.rsTmpClienteEtiquetas}]
          idStatus: ${this.idTmpStatus}
          rsClienteAcompanhamento: ${Utils.ConverterEmGql( model )}
        ) {
          ok
          mensagem
        }
      }
    `

    Logger.logar( sqlIncluir, 'sql Incluir' )
    // this.layout.exibirLoading = true

    clsApollo.apolloMutation( this, sqlIncluir, 'atualizarClienteAcompanhamento', true ).then( ( resultMutation: any ) => {

      this.descricaoAcompanhamento = ''
      this.keyRefresh++
      this.$emit( 'onChange' )

    } ).catch( err => {

      this.layout.mensagem = 'Erro ao atualizar!!!!'
      this.layout.exibirMensagem = true

    } )
  }

  private ChecarAlteracao ( campo: tipoCampoAlteracaoType,
    origem: Array<number> | number,
    destino: Array<number> | number ) {

    let nomeCampo: string = ['Status', 'Etiqueta', 'Responsável'][campo]

    if ( !_.isEqual( origem, destino ) ) {
      this.descricaoAcompanhamento = this.descricaoAcompanhamento.concat( "\n\n\n" )
      this.descricaoAcompanhamento = this.descricaoAcompanhamento.concat( nomeCampo ).concat( ": [" )
      this.descricaoAcompanhamento = this.descricaoAcompanhamento.concat( this.getDescricaoAlteracao( campo, origem ) )
      this.descricaoAcompanhamento = this.descricaoAcompanhamento.concat( "] ==> [" )
      this.descricaoAcompanhamento = this.descricaoAcompanhamento.concat( this.getDescricaoAlteracao( campo, destino ) )
      this.descricaoAcompanhamento = this.descricaoAcompanhamento.concat( "]" )
    }
  }

  private getDescricaoAlteracao ( campo: tipoCampoAlteracaoType, origem: Array<number> | number ): string {
    let retorno: string = ""

    if ( campo == tipoCampoAlteracaoType.STATUS ) {
      retorno = <string>this.clsCrudVue.getStatus( <number>origem ).descricao
    } else if ( campo == tipoCampoAlteracaoType.ETIQUETAS ) {

      _.forEach( <any>origem, ( idEtiqueta: number, indice: number ) => {

        if ( indice > 0 ) {
          retorno = retorno.concat( "," )
        }

        retorno = retorno.concat( <string>this.clsCrudVue.getEtiqueta( idEtiqueta ).descricao )

      } )

    } else if ( campo == tipoCampoAlteracaoType.USUARIO ) {

      _.forEach( <any>origem, ( idUsuario: number, indice: number ) => {

        if ( indice > 0 ) {
          retorno = retorno.concat( "," )
        }

        retorno = retorno.concat( <string>this.clsCrudVue.getUsuario( idUsuario ).nome )

      } )

    }

    return retorno

  }

}

