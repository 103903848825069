import ClsApollo from "@/utils/ClsApollo"
import * as Bluebird from "bluebird"
import { stateCadastrosInterface } from "@/interfaces/StoreInterface"
import { UsuarioAttributes, EtiquetaAttributes, CanalAttributes, StatusAttributes, CursoCategoriaAttributes, CursoAttributes } from "@/interfaces/backend/ModelAttributesInterface"

const SQLBAIRROS: string = `
  query {
    listRaw(inputListRaw: {
      sql: "select bairro from clientes group by bairro order by bairro"
      replacements: {}
      type: "SELECT"
    })
  }  
`

const SQLCIDADES: string = `
  query {
    listRaw(inputListRaw: {
      sql: "select cidade from clientes group by cidade order by cidade"
      replacements: {}
      type: "SELECT"
    })
  }
`

const SQLUSUARIOS: string = `
  query {
    list(inputCrud: {
      tabela: "Usuario"
      list: {
        attributes: ["idUsuario","nome","ativo"]
      }
    })
  }
`

const SQLCURSOSCATEGORIAS: string = `
  query {
    list(inputCrud: {
      tabela: "CursoCategoria"
      list: {
        attributes: ["idCursoCategoria","descricao","ativo"]
      }
    })
  }
`

const SQLETIQUETAS: string = `
  query {
    list(inputCrud: {
      tabela: "Etiqueta"
      list: {
        attributes: ["idEtiqueta","descricao"]
      }
    })
  }
`

const SQLSTATUS: string = `
  query {
    list(inputCrud: {
      tabela: "Status"
      list: {
        attributes: ["idStatus","descricao","concluir"]
      }
    })
  }
`

const SQLCANAL: string = `
  query {
    list(inputCrud: {
      tabela: "Canal"
      list: {
        attributes: ["idCanal","descricao","ativo"]
      }
    })
  }
`

const SQLCURSOS: string = `
  query {
    list(inputCrud: {
      tabela: "Curso"
      list: {
        attributes: ["idCurso","descricao","ativo"]
      }
    })
  }
`

export default class ClsCadastrosStore {

  private clsApollo = new ClsApollo()

  public refreshAll ( thisPai: any ): Bluebird<stateCadastrosInterface> {

    let retorno: stateCadastrosInterface = {
      rsBairros: [],
      rsCanais: [],
      rsCidades: [],
      rsCursos: [],
      rsCursosCategorias: [],
      rsEtiquetas: [],
      rsStatus: [],
      rsUsuarios: []
    }

    let prCadastros: Array<Bluebird<any>> = []

    prCadastros.push( this.refreshBairros( thisPai ) )
    prCadastros.push( this.refreshCanais( thisPai ) )
    prCadastros.push( this.refreshCidades( thisPai ) )
    prCadastros.push( this.refreshCursos( thisPai ) )
    prCadastros.push( this.refreshCursosCategorias( thisPai ) )
    prCadastros.push( this.refreshEtiquetas( thisPai ) )
    prCadastros.push( this.refreshStatus( thisPai ) )
    prCadastros.push( this.refreshUsuarios( thisPai ) )

    return Bluebird.all( prCadastros ).then( rsAll => {

      retorno.rsBairros = rsAll[0]
      retorno.rsCanais = rsAll[1]
      retorno.rsCidades = rsAll[2]
      retorno.rsCursos = rsAll[3]
      retorno.rsCursosCategorias = rsAll[4]
      retorno.rsEtiquetas = rsAll[5]
      retorno.rsStatus = rsAll[6]
      retorno.rsUsuarios = rsAll[7]
      return retorno
    } )

  }

  public refreshClientes ( thisPai: any ): Bluebird<any> {

    let retorno: any = {
      rsBairros: [],
      rsCidades: []
    }

    let prCadastros: Array<Bluebird<any>> = []

    prCadastros.push( this.refreshBairros( thisPai ) )
    prCadastros.push( this.refreshCidades( thisPai ) )

    return Bluebird.all( prCadastros ).then( rsAll => {
      retorno.rsBairros = rsAll[0]
      retorno.rsCidades = rsAll[1]
      return retorno
    } )

  }

  private refreshCidades ( thisPai: any ): Bluebird<Array<string>> {
    return this.refreshCadastro( thisPai, SQLCIDADES, 'cidade' )
  }

  private refreshBairros ( thisPai: any ): Bluebird<Array<string>> {
    return this.refreshCadastro( thisPai, SQLBAIRROS, 'bairro' )
  }

  private refreshCanais ( thisPai: any ): Bluebird<Array<CanalAttributes>> {
    return this.refreshTabela( thisPai, SQLCANAL )
  }

  private refreshStatus ( thisPai: any ): Bluebird<Array<StatusAttributes>> {
    return this.refreshTabela( thisPai, SQLSTATUS )
  }

  private refreshUsuarios ( thisPai: any ): Bluebird<Array<UsuarioAttributes>> {
    return this.refreshTabela( thisPai, SQLUSUARIOS )
  }

  private refreshEtiquetas ( thisPai: any ): Bluebird<Array<EtiquetaAttributes>> {
    return this.refreshTabela( thisPai, SQLETIQUETAS )
  }

  private refreshCursosCategorias ( thisPai: any ): Bluebird<Array<CursoCategoriaAttributes>> {
    return this.refreshTabela( thisPai, SQLCURSOSCATEGORIAS )
  }

  private refreshCursos ( thisPai: any ): Bluebird<Array<CursoAttributes>> {
    return this.refreshTabela( thisPai, SQLCURSOS )
  }

  private refreshCadastro ( thisPai: any, sql: string, nomeCampoRetorno: string ): Bluebird<Array<string>> {
    return this.clsApollo
      .apolloQuery( thisPai, sql, 'listRaw' )
      .then( ( rs: any ) => {
        return rs.map( ( v: any ) => { return v[nomeCampoRetorno] } )
      } )

  }

  private refreshTabela ( thisPai: any, sql: string ): Bluebird<Array<any>> {
    return this.clsApollo
      .apolloQuery( thisPai, sql, 'list' )
      .then( ( rs: any ) => {
        return rs
      } )
  }


}