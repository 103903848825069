
























































import { Component, Prop, Vue } from 'vue-property-decorator'
import Utils from './utils/Utils';

import { emDESENVOLVIMENTO } from './includes/config/setupDesenvolvimento'
import { ClsCrudVue } from './components/crud/ClsCrudVue';
import MenuAplicacoes from './components/layout/MenuAplicacoes.vue'
import MenuMinhaConta from './components/layout/MenuMinhaConta.vue'
import MenuRelatorios from './components/layout/MenuRelatorios.vue'

import store from '@/store'
import { THEME } from './plugins/vuetify';
import Logger from './utils/Logger';

@Component( {
  components: {
    MenuAplicacoes,
    MenuMinhaConta,
    MenuRelatorios
  }
} )
export default class App extends Vue {

  private THEME = THEME

  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this )

  private exibirControleDrawerRelatorio: boolean = false

  private btMenuRelatorios (): void {
    this.exibirControleDrawerRelatorio = !this.exibirControleDrawerRelatorio
  }

  // ALTERAPERMISSAO - V1 - FRONTEND - Alterar Quando incluir nova Permissao do Sistema
  private get permitirAplicacoes (): boolean {
    return this.clsCrudVue.PERMISSOES.USUARIO.EXIBEMENU
      || this.clsCrudVue.PERMISSOES.GERAL.EXIBEMENU
      || this.clsCrudVue.PERMISSOES.GRUPO.EXIBEMENU
      || this.clsCrudVue.PERMISSOES.CLIENTE.EXIBEMENU
      || this.clsCrudVue.PERMISSOES.CLIENTEACOMPANHAMENTO.EXIBEMENU
      || this.clsCrudVue.PERMISSOES.AGENDA.EXIBEMENU
      || this.clsCrudVue.PERMISSOES.CURSO.EXIBEMENU
  }

  private get exibirNavegacao (): boolean {
    // return this.tmpExibirNavegacao && store.state.login.logado
    return store.state.login.logado
  }

  private get emDesenvolvimento (): boolean {
    return emDESENVOLVIMENTO
  }

  private get token (): string {
    return store.state.login.token
  }

  private get larguraMenu (): number {
    let retorno: number = 350
    if ( Utils.isMobile || Utils.isMobileLandScape ) {
      retorno = Utils.width
    }
    return retorno
  }

}

