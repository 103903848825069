

























import { Component, Prop, Vue } from "vue-property-decorator";

import { LayOutInterface, statusCrudInterface } from "@/interfaces/LayOutInterface";

import MensagemForm from "@/includes/auxForms/MensagemForm.vue";
import Loading from "@/includes/auxForms/Loading.vue";
import ErroWs from "@/includes/auxForms/ErroWs.vue";
import Utils from '@/utils/Utils';
import ClsApollo from "../utils/ClsApollo";
import Logger from "../utils/Logger";
import { configBACKEND } from "../includes/config/configServidor";
import gql from "graphql-tag";
import { ClienteContatoAttributes } from "../interfaces/backend/ModelAttributesInterface";
import moment from "moment";
import { DTFORMAT } from "../includes/config/DataTypesAplicacao";

import { ICONES } from '@/includes/config/parametros'

import DataTableResponsive, { cabecalhoInterface } from '@/includes/auxForms/DataTableResponsive.vue'
import { ClsClientePesquisa, modelClientePesquisaInterface } from "../components/crud/clientes/ClsClientePesquisa";

import InputDatePicker from '@/includes/components/InputDatePicker.vue'

import ClienteExibirAcompanhamento from '@/components/crud/clientes/cliente.exibir.acompanhamento.vue'
import ClienteExibirCadastro from '@/components/crud/clientes/cliente.exibir.cadastro.vue'
import ClienteCrudAcompanhamento from '@/components/crud/clientes/cliente.crud.acompanhamento.vue'
import { ClsCrudVue } from "../components/crud/ClsCrudVue";
import ClsLogin from "../components/login/ClsLogin";
import store from "../store";

@Component( {
  components: {
    MensagemForm,
    Loading,
    ErroWs,
    DataTableResponsive,
    InputDatePicker,
    ClienteExibirCadastro,
    ClienteExibirAcompanhamento,
    ClienteCrudAcompanhamento

  }
} )
export default class Testes extends Vue {

  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this, 'Testes' )

  private layout: LayOutInterface = {
    exibirMensagem: false,
    mensagem: "",
    exibirLoading: false,
    erroWebService: false,
    frmValid: false,
    crud: statusCrudInterface.pesquisando
  }

  //private clsLogin: ClsLogin = new ClsLogin( this )

  private imprimir: any = '28/03/2020'

  private mounted (): any {
//`${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`          : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`
  }

  private events = [{
    name: 'Flex 10',
    start: '2020-04-01T10:00',
    end: '2020-04-01T11:00',
    color: 'green'
  },
{
    name: 'Flex 10',
    start: '2020-04-01T10:45',
    end: '2020-04-01T11:45',
    color: 'indigo'
  }]

}
