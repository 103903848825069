






























































































































import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  LayOutInterface,
  statusCrudInterface,
} from '@/interfaces/LayOutInterface'

import MensagemForm from '@/includes/auxForms/MensagemForm.vue'
import ConfirmDialog from '@/includes/auxForms/ConfirmDialog.vue'
import Loading from '@/includes/auxForms/Loading.vue'
import ErroWs from '@/includes/auxForms/ErroWs.vue'

import crudPesquisa from '@/includes/auxForms/CrudPesquisa.vue'

import Validadores from '@/utils/Validadores'
import btCrud from '@/includes/components/btCrud.vue'

import * as _ from 'lodash'
import Utils from '@/utils/Utils'
import ClsApollo from '@/utils/ClsApollo'
import { ICONES } from '@/includes/config/parametros'

import { ClsCrudVue } from './ClsCrudVue';
import { GrupoAttributes } from '../../interfaces/backend/ModelAttributesInterface';
import Logger from '../../utils/Logger';

@Component( {
  components: {
    MensagemForm,
    Loading,
    ErroWs,
    ConfirmDialog,
    btCrud,
    crudPesquisa
  },
} )

export default class Crud extends Vue {
  /**
    * Definicoes PADROES
    */

  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this, 'CadastroGrupo' )

  private get Validadores () {
    return Validadores
  }

  private layout: LayOutInterface = this.clsCrudVue.layOutInicial

  /**
   * Crud
   */

  private model: GrupoAttributes = this.resetModel()

  private rsTemp: GrupoAttributes = this.resetModel()

  private created (): void {
    this.clsCrudVue.redirecionar( this, this.clsCrudVue.PERMISSOES.GRUPO )
  }

  private mounted (): void {
    this.resetInicial()
  }

  private resetModel (): GrupoAttributes {
    return {
      idGrupo: 0,
      descricao: ""
    }
  }

  private resetInicial (): void {
    this.layout.crud = this.clsCrudVue.StatusCrud.pesquisando
    this.rsPesquisa.registros = []
    this.rsPesquisa.pesquisa = ''

    this.model = this.resetModel()
    // @ts-ignore
    this.$refs.txtPesquisa.focus()
  }

  private rsPesquisa = {
    pesquisa: '',
    registros: []
  }

  private btPesquisar (): void {
    let clsApollo: ClsApollo = new ClsApollo()

    const sqlPesquisa = `

      query {
        list(inputCrud: {
          tabela: "Grupo",
          list: {
            attributes: ["idGrupo","descricao"],
            camposLike: ["descricao"],
            conteudoLike: ["${this.rsPesquisa.pesquisa}"]
          }
        }) 
      }

    `

    clsApollo
      .apolloQuery( this, sqlPesquisa, 'list' )
      .then( ( resultQuery: any ) => {
        this.rsPesquisa.registros = resultQuery
      } )
  }

  private obterDadosParaModel ( registro: GrupoAttributes ): void {

    let clsApollo: ClsApollo = new ClsApollo()

    const sqlPesquisa = `

      query {
        getById(inputCrud: {
          tabela: "Grupo"
          campoChave: "idGrupo"
          getById: ${registro.idGrupo}
        })
      }    

    `

    clsApollo
      .apolloQuery( this, sqlPesquisa, 'getById' )
      .then( ( resultQuery: any ) => {
        this.model = { ...resultQuery }
        /*
                let retorno: any = _.find( this.rsGrupoSubGrupo, function ( o: RelatorioSubGrupoAttributes ) { return o.idRelatorioSubGrupo == this.model.idRelatorioSubGrupo }.bind( this ) )
        
                if ( retorno ) {
                  this.tmpidRelatorioGrupo = retorno.idRelatorioGrupo
                }
        */
      } )

  }

  private btEditarItem ( registro: GrupoAttributes ): void {
    this.obterDadosParaModel( registro )
    this.layout.crud = this.clsCrudVue.StatusCrud.editando
  }

  private btExcluirItem ( registro: GrupoAttributes ): void {
    this.obterDadosParaModel( registro )
    this.layout.crud = this.clsCrudVue.StatusCrud.excluindo
  }

  private btCancel (): void {
    this.layout.crud = this.clsCrudVue.StatusCrud.pesquisando
  }

  private btIncluir (): void {

    this.model = this.resetModel()

    this.layout.crud = this.clsCrudVue.StatusCrud.incluindo

    // @ts-ignore
    this.$refs.txtDescricao.focus()

  }

  private btConfirmarInclusaoEdicao (): void {

    let tmpModel: GrupoAttributes = { ... this.model }

    this.clsCrudVue.confirmarInclusaoEdicao( {
      nomeFrmCadastro: 'frmCadastro',
      model: tmpModel,
      fnPosMutationOK: this.resetInicial,
      campoChave: "idGrupo",
      tabela: "Grupo"
    } )

  }

  private btConfirmarExclusao (): void {

    this.clsCrudVue.confirmarExclusao( {
      valorId: this.model.idGrupo,
      campoChave: 'idGrupo',
      tabela: 'Grupo',
      msgAviso: 'Excluir '.concat( this.model.descricao ).concat( '?' ),
      fnPosMutationOK: this.resetInicial
    } )

  }

  private btClickPaste () {

    this.model = <any>this.clsCrudVue.paste( this.model )

  }

}
