

























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import {
  LayOutInterface,
  statusCrudInterface,
} from '@/interfaces/LayOutInterface'

import MensagemForm from '@/includes/auxForms/MensagemForm.vue'
import ConfirmDialog from '@/includes/auxForms/ConfirmDialog.vue'
import Loading from '@/includes/auxForms/Loading.vue'
import ErroWs from '@/includes/auxForms/ErroWs.vue'

import crudPesquisa, { cabecalhoCrudPesquisaInterface } from '@/includes/auxForms/CrudPesquisa.vue'
import CrudDetalheSelect from '@/includes/auxForms/CrudDetalheSelect.vue'

import btCrud from '@/includes/components/btCrud.vue'

import * as _ from 'lodash'
import * as Bluebird from 'bluebird'
import Utils from '@/utils/Utils'
import ClsApollo from '@/utils/ClsApollo'
import { ICONES } from '@/includes/config/parametros'

import { ClsCrudVue } from '@/components/crud/ClsCrudVue'
import { ClienteAttributes, ClienteContatoAttributes, ClienteUsuarioAttributes, ClienteAcompanhamentoAttributes, ClienteEtiquetaAttributes, ClienteCursoAttributes } from '@/interfaces/backend/ModelAttributesInterface'
import Logger from '@/utils/Logger'

import ClienteCrudCadastro from './cliente.crud.cadastro.vue'

import store from '@/store'

// @ts-ignore
import { mask } from 'vue-the-mask'
import { TiposContatoType } from '@/includes/config/typeTiposContato'
import { stateRootInterface } from '../../../interfaces/StoreInterface'
import { emDESENVOLVIMENTO } from '../../../includes/config/setupDesenvolvimento'
import moment from 'moment'
import { DTFORMAT } from '../../../includes/config/DataTypesAplicacao'

@Component( {
  directives: {
    mask
  },
  components: {
    MensagemForm,
    Loading,
    ErroWs,
    ConfirmDialog,
    btCrud,
    crudPesquisa,
    CrudDetalheSelect,
    ClienteCrudCadastro
  },
} )

export default class Crud extends Vue {

  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this, 'EditarCliente' )

  private layout: LayOutInterface = this.clsCrudVue.layOutInicial

  @Prop()
  //@ts-ignore
  private rsCliente: ClienteAttributes

  @Prop()
  //@ts-ignore
  private rsClienteContatos: Array<ClienteContatoAttributes>

  @Prop()
  //@ts-ignore
  private rsClienteCursos: Array<ClienteCursoAttributes>

  @Prop()
  //@ts-ignore
  private rsClienteUsuarios: Array<ClienteUsuarioAttributes>

  @Prop()
  //@ts-ignore
  private rsClienteEtiquetas: Array<ClienteEtiquetaAttributes>

  @Watch( 'rsClienteContatos' )
  private onChangersClienteContatos ( newValue: Array<ClienteContatoAttributes>, oldValue: Array<ClienteContatoAttributes> ) {
    this.rsTmpClienteContatos = [...this.rsClienteContatos]
  }

  @Watch( 'rsClienteCursos' )
  private onChangersClienteCursos ( newValue: Array<ClienteCursoAttributes>, oldValue: Array<ClienteCursoAttributes> ) {
    this.rsTmpClienteCursos = [...this.rsClienteCursos]
  }

  @Watch( 'rsClienteUsuarios' )
  private onChangersClienteUsuarios ( newValue: Array<ClienteContatoAttributes>, oldValue: Array<ClienteContatoAttributes> ) {
    this.rsTmpClienteUsuarios = [...this.rsClienteUsuarios]
  }

  @Watch( 'rsClienteEtiquetas' )
  private onChangersClienteEtiquetas ( newValue: Array<ClienteContatoAttributes>, oldValue: Array<ClienteContatoAttributes> ) {
    this.rsTmpClienteEtiquetas = [...this.rsClienteEtiquetas]
  }

  @Watch( 'rsCliente' )
  private onChangersCliente ( newValue: Array<ClienteContatoAttributes>, oldValue: Array<ClienteContatoAttributes> ) {
    this.model = { ...this.rsCliente }
  }

  private rsTmpClienteContatos: Array<ClienteContatoAttributes> = []
  private rsTmpClienteCursos: Array<ClienteCursoAttributes> = []
  private rsTmpClienteUsuarios: Array<ClienteUsuarioAttributes> = []
  private rsTmpClienteEtiquetas: Array<ClienteEtiquetaAttributes> = []
  private model: ClienteAttributes = {}

  private created (): void {
    this.clsCrudVue.redirecionar( this, this.clsCrudVue.PERMISSOES.CLIENTE.PERMISSAO.UPDATE )
  }

  private mounted (): void {
    this.layout.crud = this.clsCrudVue.StatusCrud.editando
  }

  private btCancel (): void {
    this.$emit( "btCancel" )
  }

  private onChangeRsTmpClienteContatos ( rsTmpClienteContatos: Array<ClienteContatoAttributes> ): void {
    this.rsTmpClienteContatos = rsTmpClienteContatos
  }

  private onChangeRsTmpClienteCursos ( rsTmpClienteCursos: Array<ClienteCursoAttributes> ): void {
    this.rsTmpClienteCursos = rsTmpClienteCursos
  }

  private onChangeRsTmpClienteUsuarios ( rsTmpClienteUsuarios: Array<ClienteUsuarioAttributes> ): void {
    this.rsTmpClienteUsuarios = rsTmpClienteUsuarios
  }

  private onChangeRsTmpClienteEtiquetas ( rsTmpClienteEtiquetas: Array<ClienteUsuarioAttributes> ): void {
    this.rsTmpClienteEtiquetas = rsTmpClienteEtiquetas
  }

  private btConfirmarInclusaoEdicao (): void {

    let tmpModel: ClienteAttributes = { ... this.model }

    tmpModel.dataNascimento = Utils.converterDataParaBanco( <string>tmpModel.dataNascimento )

    if ( !tmpModel.dataNascimento ) delete tmpModel.dataNascimento

    //@ts-ignore
    if ( this.$refs.frmCadastro.validate() ) {

      this.clsCrudVue.confirmarInclusaoEdicao( {

        nomeFrmCadastro: 'frmCadastro',
        model: tmpModel,
        fnPosMutationOK: this.btCancel,
        campoChave: "idCliente",
        tabela: "Cliente",
        detalhe: [{
          modeloDetalhe: "ClienteContato",
          campoChaveAcrescentarDetalhe: "idCliente",
          dados: this.rsTmpClienteContatos
        },
        {
          modeloDetalhe: "ClienteUsuario",
          campoChaveAcrescentarDetalhe: "idCliente",
          dados: Utils.transformarVetorEmObjetoRS( this.rsTmpClienteUsuarios, 'idUsuario' )
        },
        {
          modeloDetalhe: "ClienteEtiqueta",
          campoChaveAcrescentarDetalhe: "idCliente",
          dados: Utils.transformarVetorEmObjetoRS( this.rsTmpClienteEtiquetas, 'idEtiqueta' )
        },
        {
          modeloDetalhe: "ClienteCurso",
          campoChaveAcrescentarDetalhe: "idCliente",
          dados: this.rsTmpClienteCursos
        }]
      } )
    }

  }

}
