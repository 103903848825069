export const enum MotivoLogoutType {
  USUARIO = 'USUARIO',
  TEMPO = 'TEMPO',
  OUTRO_LOGIN_USUARIO = 'OUTRO_LOGIN_USUARIO',
  OUTRO_LOGIN_TOKEN = 'OUTRO_LOGIN_TOKEN',
  LOGIN_POR_TOKEN = 'LOGIN_POR_TOKEN',
  ALTERACAO_SENHA = 'ALTERACAO_SENHA'
}

export const DTFORMAT = Object.freeze( {
  USUARIO: 'DD/MM/YYYY',
  USUARIO_DATETIME: 'DD/MM/YYYY HH:mm:ss',
  BANCO: 'YYYY-MM-DD',
  BANCO_DATETIME: 'YYYY-MM-DD HH:mm:ss',
  UTC: 'YYYY-MM-DDTHH:mm:ssZ'
} )

export const DIASDASEMANA = Object.freeze( [
  { idDia: 0, descricao: 'Domingo' },
  { idDia: 1, descricao: 'Segunda' },
  { idDia: 2, descricao: 'Terca' },
  { idDia: 3, descricao: 'Quarta' },
  { idDia: 4, descricao: 'Quinta' },
  { idDia: 5, descricao: 'Sexta' },
  { idDia: 6, descricao: 'Sabado' }
] )
