








































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import {
  LayOutInterface,
  statusCrudInterface,
} from '@/interfaces/LayOutInterface'

import MensagemForm from '@/includes/auxForms/MensagemForm.vue'
import ConfirmDialog from '@/includes/auxForms/ConfirmDialog.vue'
import Loading from '@/includes/auxForms/Loading.vue'
import ErroWs from '@/includes/auxForms/ErroWs.vue'

import crudPesquisa, { cabecalhoCrudPesquisaInterface } from '@/includes/auxForms/CrudPesquisa.vue'
import CrudDetalheSelect from '@/includes/auxForms/CrudDetalheSelect.vue'

import btCrud from '@/includes/components/btCrud.vue'

import * as _ from 'lodash'
import * as Bluebird from 'bluebird'
import Utils from '@/utils/Utils'
import ClsApollo from '@/utils/ClsApollo'
import { ICONES } from '@/includes/config/parametros'

import { ClsCrudVue } from '@/components/crud/ClsCrudVue'
import { ClienteAttributes, ClienteContatoAttributes, ClienteUsuarioAttributes, ClienteAcompanhamentoAttributes, OportunidadeAttributes } from '@/interfaces/backend/ModelAttributesInterface'
import Logger from '@/utils/Logger'

import CrudClienteCadastro from './cliente.crud.cadastro.vue'
import CrudClienteAcompanhamento from './cliente.crud.acompanhamento.vue'

import store from '@/store'

// @ts-ignore
import { mask } from 'vue-the-mask'
import { TiposContatoType } from '@/includes/config/typeTiposContato'
import { stateRootInterface } from '../../../interfaces/StoreInterface'
import { emDESENVOLVIMENTO } from '../../../includes/config/setupDesenvolvimento'

@Component( {
  directives: {
    mask
  },
  components: {
    MensagemForm,
    Loading,
    ErroWs,
    ConfirmDialog,
    btCrud,
    crudPesquisa,
    CrudDetalheSelect,
    CrudClienteCadastro,
    CrudClienteAcompanhamento
  },
} )

export default class Crud extends Vue {
  /**
    * Definicoes PADROES
    */

  private rsClientesContatos: Array<ClienteContatoAttributes> = []
  private rsClienteUsuarios: Array<ClienteUsuarioAttributes> = []
  private rsClienteAcompanhamento: Array<ClienteAcompanhamentoAttributes> = []
  private rsOportunidades: Array<OportunidadeAttributes> = []

  private keyRefresh: number = 0

  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this, 'CadastroCliente' )

  private layout: LayOutInterface = this.clsCrudVue.layOutInicial

  private indiceAba: number = 0

  /**
   * Crud
   */

  private model: ClienteAttributes = this.resetModel()

  private created (): void {
    this.clsCrudVue.redirecionar( this, this.clsCrudVue.PERMISSOES.CLIENTE.EXIBEMENU )
  }

  private mounted (): void {
    this.resetInicial()
  }

  private resetModel (): ClienteAttributes {

    this.rsClientesContatos = []
    this.rsClienteUsuarios = []
    this.rsClienteAcompanhamento = []
    this.rsOportunidades = []

    /*
      this.rsClienteUsuarios = [{idUsuario: 1}]
      this.rsClientesContatos = [{descricao: 'WhatsApp',tipo: 'WhatsApp'}]
    */

    // TODO - RETIRAR EM PRODUCAO

    let retorno: any = {}

    if ( emDESENVOLVIMENTO ) {
      retorno = {
        idCliente: 0,
        nome: 'Teste de Nome',
        genero: 'M',
        dataNascimento: '24/06/1974',
        bairro: "Centro",
        cidade: "Divinópolis"
      }
    } else {
      retorno = {
        idCliente: 0,
        nome: '',
        genero: '',
        dataNascimento: '',
        bairro: "",
        cidade: ""
      }
    }

    return retorno
  }

  private resetInicial (): void {
    this.layout.crud = this.clsCrudVue.StatusCrud.pesquisando
    this.rsPesquisa.registros = []
    this.rsPesquisa.pesquisa = ''

    this.model = this.resetModel()
    // @ts-ignore
    this.$refs.txtPesquisa.focus()

    this.keyRefresh++
  }

  private rsPesquisa = {
    pesquisa: '',
    registros: [],
  }

  private btPesquisar (): void {
    let clsApollo: ClsApollo = new ClsApollo()

    const sqlPesquisa = `

      query {
        listRaw(inputListRaw: {
          sql: "SELECT cl.idCliente, nome, dataNascimento, bairro, cidade, descricao, tipo FROM clientes AS cl LEFT JOIN clientescontatos AS cc ON cl.idCliente = cc.idCliente WHERE nome LIKE :pesquisa OR descricao LIKE :pesquisa LIMIT 100;"
          replacements: {pesquisa: "%${this.rsPesquisa.pesquisa}%"}
        })
      }

    `

    clsApollo
      .apolloQuery( this, sqlPesquisa, 'listRaw' )
      .then( ( resultQuery: any ) => {
        this.rsPesquisa.registros = resultQuery

        // @ts-ignore
        this.$refs.txtPesquisa.focus()

      } )
  }

  private obterDadosParaModel ( registro: ClienteAttributes ): Bluebird<any> {

    let clsApollo: ClsApollo = new ClsApollo()

    const sqlClienteOportunidades = `
 
      query {
        list(inputCrud: {
          tabela: "Oportunidade"
          list: {
            attributes: ["idOportunidade","idCliente","data","descricao","idCanal","idStatus","faixaEtaria","idUsuarioCadastro","idUsuario","idOrigem","valor","concluido","createdAt","updatedAt"]
            where: {
              idCliente: ${registro.idCliente}
            }
          }
        })
      }

    `

    const sqlClienteAcompanhamento = `

      query {
        list(inputCrud: {
          tabela: "ClienteAcompanhamento"
          list: {
            attributes: ["idAcompanhamento","idCliente","descricao","data","idCanal","idUsuario","createdAt","updatedAt"]
            where: {
              idCliente: ${registro.idCliente}
            }
          }
        })
      }

    `

    const sqlClienteContatos = `

      query {
        list(inputCrud: {
          tabela: "ClienteContato"
          list: {
            attributes: ["descricao","tipo"]
            where: {
              idCliente: ${registro.idCliente}
            }
          }
        })
      }

    `

    const sqlClienteUsuarios = `

      query {
        list(inputCrud: {
          tabela: "ClienteUsuario"
          list: {
            attributes: ["idUsuario"]
            where: {
              idCliente: ${registro.idCliente}
            }
          }
        })
      }

    `

    const sqlPesquisa = `

      query {
        getById(inputCrud: {
          tabela: "Cliente"
          campoChave: "idCliente"
          getById: ${registro.idCliente}
        })
      }    

    `

    this.model = this.resetModel()

    return clsApollo
      .apolloQuery( this, sqlPesquisa, 'getById' )
      .then( ( resultQuery: ClienteAttributes ) => {

        resultQuery.dataNascimento = Utils.converterDataParaUsuario( resultQuery.dataNascimento )

        this.model = { ...resultQuery }

        this.layout.exibirLoading = true

        return clsApollo.apolloQuery( this, sqlClienteContatos, 'list', false, "no-cache", false ).then( rsClientesContatos => {
          this.rsClientesContatos = rsClientesContatos

          return clsApollo.apolloQuery( this, sqlClienteUsuarios, 'list', false, "no-cache", false ).then( rsClienteUsuarios => {

            this.rsClienteUsuarios = rsClienteUsuarios.map( ( v: any ) => { return v.idUsuario } )

            return clsApollo.apolloQuery( this, sqlClienteAcompanhamento, 'list', false, "no-cache", false ).then( rsClienteAcompanhamento => {
              this.rsClienteAcompanhamento = rsClienteAcompanhamento

              return clsApollo.apolloQuery( this, sqlClienteOportunidades, 'list', false, "no-cache", false ).then( rsOportunidades => {
                this.rsOportunidades = rsOportunidades

                this.layout.exibirLoading = false
              } )

            } )

          } )

        } )

      } )

  }

  private btEditarItem ( registro: ClienteAttributes ): void {
    this.obterDadosParaModel( registro ).then( rs => {

      // Setar para Acompanhamentos
      this.indiceAba = 1

      this.layout.crud = this.clsCrudVue.StatusCrud.editando
      this.keyRefresh++
    } )
  }

  private btExcluirItem ( registro: ClienteAttributes ): void {
    this.obterDadosParaModel( registro ).then( rs => {
      this.indiceAba = 0
      this.layout.crud = this.clsCrudVue.StatusCrud.excluindo
      this.keyRefresh++
    } )
  }

  private btCancel (): void {
    this.layout.crud = this.clsCrudVue.StatusCrud.pesquisando
    this.keyRefresh++
  }

  private btIncluir (): void {

    this.model = this.resetModel()

    this.layout.crud = this.clsCrudVue.StatusCrud.incluindo

    this.indiceAba = 0

    this.keyRefresh++

  }

  private btConfirmarInclusaoEdicao (): void {

    let tmpModel: ClienteAttributes = { ... this.model }

    tmpModel.dataNascimento = Utils.converterDataParaBanco( <string>tmpModel.dataNascimento )

    if ( !tmpModel.dataNascimento ) delete tmpModel.dataNascimento

    this.clsCrudVue.confirmarInclusaoEdicao( {

      nomeFrmCadastro: 'frmCadastro',
      model: tmpModel,
      fnPosMutationOK: this.resetInicial,
      campoChave: "idCliente",
      tabela: "Cliente",
      detalhe: [{
        modeloDetalhe: "ClienteContato",
        campoChaveAcrescentarDetalhe: "idCliente",
        dados: this.rsClientesContatos
      },
      {
        modeloDetalhe: "ClienteUsuario",
        campoChaveAcrescentarDetalhe: "idCliente",
        dados: Utils.transformarVetorEmObjetoRS( this.rsClienteUsuarios, 'idUsuario' )
      },
      {
        modeloDetalhe: "ClienteAcompanhamento",
        campoChaveAcrescentarDetalhe: "idCliente",
        dados: this.rsClienteAcompanhamento
      },
      {
        modeloDetalhe: "Oportunidade",
        campoChaveAcrescentarDetalhe: "idCliente",
        dados: this.rsOportunidades
      }]
    } )

  }

  private btConfirmarExclusao (): void {

    this.clsCrudVue.confirmarExclusao( {
      // @ts-ignore
      valorId: this.model.idCliente,
      campoChave: 'idCliente',
      tabela: 'Cliente',
      // @ts-ignore
      msgAviso: 'Excluir '.concat( this.model.nome ).concat( '?' ),
      fnPosMutationOK: this.resetInicial
    } )

  }

  private btClickPaste () {

    this.model = <any>this.clsCrudVue.paste( this.model )

    this.keyRefresh++

  }

  private onChangersClientesContatos ( contatos: Array<ClienteContatoAttributes> ): void {
    this.rsClientesContatos = contatos
  }

  private onChangersClienteUsuarios ( rsClienteUsuarios: Array<ClienteUsuarioAttributes> ): void {
    this.rsClienteUsuarios = rsClienteUsuarios
  }

}
