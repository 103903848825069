import ClsApollo from "@/utils/ClsApollo"
import * as Bluebird from "bluebird"
import * as _ from "lodash"
import * as moment from "moment"
import { DTFORMAT } from "@/includes/config/DataTypesAplicacao"

const SQL_GERAL = 'SELECT diaLetivo,inicio,termino, SUM(CASE WHEN tipoAula=\'R\' THEN 1 ELSE 0 END) AS qtdReposicao, SUM(CASE WHEN tipoAula=\'E\' THEN 1 ELSE 0 END) AS qtdEscola, COUNT(*) AS qtd, \'Geral\' as tipo FROM clientescursos GROUP BY diaLetivo,inicio,termino ORDER BY diaLetivo, inicio;'

const SQL_POR_CATEGORIA = 'SELECT cc.diaLetivo,cc.inicio,cc.termino, SUM(CASE WHEN tipoAula=\'R\' THEN 1 ELSE 0 END) AS qtdReposicao, SUM(CASE WHEN tipoAula=\'E\' THEN 1 ELSE 0 END) AS qtdEscola, COUNT(*) AS qtd, cat.descricao AS tipo FROM clientescursos AS cc INNER JOIN cursos AS cr ON cc.idCurso = cr.idCurso INNER JOIN tblcursoscategorias AS cat ON cr.idCursoCategoria = cat.idCursoCategoria GROUP BY cc.diaLetivo,cc.inicio,cc.termino, cat.descricao;'

const SQL_POR_INSTRUTOR = 'SELECT cc.diaLetivo,cc.inicio,cc.termino, SUM(CASE WHEN tipoAula=\'R\' THEN 1 ELSE 0 END) AS qtdReposicao, SUM(CASE WHEN tipoAula=\'E\' THEN 1 ELSE 0 END) AS qtdEscola, COUNT(*) AS qtd, us.nome AS tipo FROM clientescursos AS cc INNER JOIN usuarios AS us ON cc.idUsuario = us.idUsuario GROUP BY cc.diaLetivo,cc.inicio,cc.termino, us.nome;'

const SQL_QUADROS = [SQL_GERAL, SQL_POR_CATEGORIA, SQL_POR_INSTRUTOR]

const FORMATOS_QUADROS = ['Geral', 'Categoria', 'Instrutores']

const CORES: Array<string> = ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1']

export interface eventsInterface {
  name: string
  start: string
  end: string
  color: string
  idCurso: number
}

export interface rsQuadroInterface {
  diaLetivo: number
  inicio: string
  termino: string
  qtd: number
  qtdReposicao: number
  qtdEscola: number
  tipo: string
  idCurso: number
}

export class ClsRelQuadroHorarios {

  private self: any
  private dataAtual: moment.Moment = moment()

  private rsEvents: Array<eventsInterface> = []

  public constructor( self: any ) {
    this.self = self
  }

  public refreshQuadro ( tipo: string ): Bluebird<boolean> {
    let indice: number = _.findIndex( FORMATOS_QUADROS, ( o ) => o == tipo )

    if ( indice >= 0 ) {

      let clsApollo: ClsApollo = new ClsApollo()

      const sql = `

        query {
          listRaw(inputListRaw: {
            sql: "${SQL_QUADROS[indice]}"
          })
        }
        `

      return clsApollo
        .apolloQuery( this.self, sql, "listRaw" )
        .then( ( resultQuery: Array<rsQuadroInterface> ) => {
          this.processarQuadro( resultQuery )
          return true
        } ).catch( err => {
          return false
        } )

    } else {
      return Bluebird.resolve( false )
    }

  }

  private processarQuadro ( rsQuadro: Array<rsQuadroInterface> ): void {

    let inicioSemana: moment.Moment = moment().startOf( 'week' )

    this.rsEvents = []

    _.forEach( rsQuadro, ( evento: rsQuadroInterface, indice: number ) => {

      let data: string = moment( inicioSemana ).add( evento.diaLetivo, 'd' ).format( DTFORMAT.BANCO )

      this.rsEvents.push( {
        name: evento.tipo.concat( ': ' ).concat( evento.qtd.toString() ).concat( evento.qtdReposicao > 0 ? ' - '.concat( evento.qtdReposicao.toString() ).concat( 'R' ) : '' ).concat( evento.qtdEscola > 0 ? ' - '.concat( evento.qtdEscola.toString() ).concat( 'E' ) : '' ),
        start: data.concat( "T" ).concat( evento.inicio ),
        end: data.concat( "T" ).concat( evento.termino ),
        color: this.getCor( evento.tipo ),
        idCurso: evento.idCurso
      } )

    } )

  }

  private coresAtribuidas: Array<string> = []

  private getCor ( tipo: string ) {
    let indice: number = _.findIndex( this.coresAtribuidas, ( o ) => o == tipo )

    if ( indice < 0 ) {
      this.coresAtribuidas.push( tipo )
      indice = this.coresAtribuidas.length - 1
    }

    if ( indice > CORES.length - 1 ) {
      indice = 0
    }

    return CORES[indice]

  }

  public get events (): Array<eventsInterface> {

    return this.rsEvents

  }

  public get dataBase (): string {
    return moment( this.dataAtual ).format( DTFORMAT.BANCO )
  }

  public get inicioSemana (): string {
    return moment( this.dataAtual ).startOf( 'week' ).add( 1, 'day' ).format( DTFORMAT.BANCO )
  }

  public get finalSemana (): string {
    return moment( this.dataAtual ).endOf( 'week' ).format( DTFORMAT.BANCO )
  }

  public get tiposQuadros (): Array<string> {
    return FORMATOS_QUADROS
  }
}