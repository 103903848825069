






































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import MensagemForm from '@/includes/auxForms/MensagemForm.vue'
import Loading from '@/includes/auxForms/Loading.vue'
import ErroWs from '@/includes/auxForms/ErroWs.vue'
import ConfirmDialog from '@/includes/auxForms/ConfirmDialog.vue'
import { LayOutInterface } from '../../../interfaces/LayOutInterface'
import { ClsCrudVue } from '../ClsCrudVue'
import Logger from '../../../utils/Logger'
import { UsuarioGrupoAttributes, UsuarioAttributes, ClienteAttributes, ClienteAcompanhamentoAttributes, ClienteContatoAttributes, ClienteUsuarioAttributes, ClienteEtiquetaAttributes } from '../../../interfaces/backend/ModelAttributesInterface'

import InputDatePicker from '@/includes/components/InputDatePicker.vue'
import Utils from '../../../utils/Utils'
import { modelClientePesquisaInterface, ClsClientePesquisa } from './ClsClientePesquisa'
import DataTableResponsive, { cabecalhoInterface, acoesInterface } from '@/includes/auxForms/DataTableResponsive.vue'
import ClsApollo from '../../../utils/ClsApollo'

import ClienteCrudAcompanhamento from './cliente.crud.acompanhamento.vue'
import ClienteExibirCadastro from './cliente.exibir.cadastro.vue'
import EditarCliente from './editar.cliente.vue'
import frmExibirContato from '../../../views/relatorios/frmExibirContato.vue'

//@ts-ignore
import { mask } from 'vue-the-mask'

interface layoutExibirInterface {
  formularioPesquisa: boolean
  acompanhamento: boolean
  cadastro: boolean
  listagem: boolean
  edicaocliente: boolean
}

@Component( {
  directives: {
    mask
  },
  components: {
    MensagemForm,
    Loading,
    ErroWs,
    ConfirmDialog,
    InputDatePicker,
    DataTableResponsive,
    ClienteCrudAcompanhamento,
    ClienteExibirCadastro,
    EditarCliente,
    frmExibirContato
  }
} )

export default class Crud extends Vue {

  private titulo: string = ""

  private cabecalhos: Array<cabecalhoInterface> = [{
    text: 'Nome',
    align: 'left',
    value: 'nome'
  },
  {
    text: 'Matricula',
    align: 'left',
    value: 'matricula'
  },
  {
    text: 'Cidade',
    align: 'left',
    value: 'cidade'
  },
  {
    text: 'Bairro',
    align: 'left',
    value: 'bairro'
  },
  {
    text: 'Data Nasc.',
    align: 'left',
    value: 'dataNascimento'
  }]

  private itens: Array<ClienteAttributes> = []

  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this, 'MeusClientes' )

  private layout: LayOutInterface = this.clsCrudVue.layOutInicial

  private layoutExibir: layoutExibirInterface = {
    formularioPesquisa: true,
    acompanhamento: false,
    cadastro: false,
    listagem: false,
    edicaocliente: false
  }

  private model: modelClientePesquisaInterface = {
    terminoAgenda: '',
    inicioAgenda: '',
    terminoCadastro: '',
    inicioCadastro: '',
    terminoAcompanhamento: '',
    inicioAcompanhamento: '',
    rsUsuarios: [],
    rsInstrutores: [],
    rsStatus: [],
    rsEtiquetas: [],
    diasLetivos: [],
    inicioAula: "",
    terminoAula: "",
    inicioFalta: "",
    terminoFalta: "",
    pesquisa: ''
  }

  private clsClientePesquisa: ClsClientePesquisa = new ClsClientePesquisa( this )

  private btPesquisar (): void {

    this.clsClientePesquisa.pesquisarCliente( this.model ).then( rs => {
      this.itens = rs
      this.titulo = 'Clientes'

      this.AllFalseExibir()

      this.layoutExibir.formularioPesquisa = true
      this.layoutExibir.listagem = true
    } )
  }

  private btPesquisarNovosClientes (): void {

    this.clsClientePesquisa.pesquisarClienteSemUsuario().then( rs => {
      this.itens = rs
      this.titulo = 'Novos Clientes'

      this.AllFalseExibir()
      this.layoutExibir.listagem = true
    } )

  }

  private acoes: Array<acoesInterface> = [{
    icone: 'ClienteAcompanhamento',
    evento: 'btAcompanhamento',
    tooltip: 'Acompanhamentos'
  },
  {
    icone: 'CrudEditar',
    evento: 'btEditar',
    tooltip: 'Editar'
  },
  {
    icone: 'Contato',
    evento: 'btContato',
    tooltip: 'Contato'
  }]

  // Acompanhamento de Clientes

  private btAcompanhamento ( rsCliente: ClienteAttributes ) {
    this.clsClientePesquisa.refreshCliente( <number>rsCliente.idCliente ).then( rs => {
      this.AllFalseExibir()
      this.layoutExibir.acompanhamento = true
      this.layoutExibir.cadastro = true
    } )
  }

  private btEditar ( rsCliente: ClienteAttributes ) {
    this.clsClientePesquisa.refreshCliente( <number>rsCliente.idCliente ).then( rs => {
      this.AllFalseExibir()
      this.layoutExibir.edicaocliente = true
    } )
  }

  private AllFalseExibir (): void {
    this.layoutExibir = {
      formularioPesquisa: false,
      acompanhamento: false,
      cadastro: false,
      listagem: false,
      edicaocliente: false
    }
  }

  private btFechar (): void {
    if ( this.layoutExibir.formularioPesquisa ) {
      this.$router.push( '/Home' )
    } else {
      this.AllFalseExibir()
      this.layoutExibir.formularioPesquisa = true
    }
  }

  private exibirContato: boolean = false
  private idClienteExibirContato: number = 0

  private btContato ( rsCliente: ClienteAttributes ): void {
    Logger.logar(rsCliente,'aqui....')
    this.idClienteExibirContato = <number>rsCliente.idCliente
    this.exibirContato = true
  }

  private fecharContato () {
    this.exibirContato = false
  }

}
