





































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import MensagemForm from '@/includes/auxForms/MensagemForm.vue'
import Loading from '@/includes/auxForms/Loading.vue'
import ErroWs from '@/includes/auxForms/ErroWs.vue'
import ConfirmDialog from '@/includes/auxForms/ConfirmDialog.vue'
import { LayOutInterface } from '../../../interfaces/LayOutInterface'
import { ClsCrudVue } from '../ClsCrudVue'


@Component( {
  components: {
    MensagemForm,
    Loading,
    ErroWs,
    ConfirmDialog
  }
} )

export default class Crud extends Vue {

  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this, 'MeusClientes' )

  private layout: LayOutInterface = this.clsCrudVue.layOutInicial
}
