import Utils from "./Utils"

const ufs = [
  'AC',
  'AL',
  'AM',
  'AP',
  'BA',
  'CE',
  'ES',
  'GO',
  'MA',
  'MG',
  'MS',
  'MT',
  'PA',
  'PB',
  'PE',
  'PI',
  'PR',
  'RJ',
  'RN',
  'RO',
  'RR',
  'RS',
  'SC',
  'SE',
  'SP',
  'TO'
]

const mesAnoValido = function validarMesAno ( mesAno: string ) {

  let retorno: boolean = false
  if ( mesAno.length == 7 ) {
    if ( mesAno.substr( 0, 2 ) >= '01' && mesAno.substr( 0, 2 ) <= '12' ) {
      retorno = true
    }
  }

  return retorno
}

const eCNPJ = function validarCNPJ ( cnpj: string ) {

  cnpj = limparMascara( cnpj )

  if ( cnpj == '' ) return false;

  if ( cnpj.length != 14 )
    return false;

  // Elimina CNPJs invalidos conhecidos
  if ( cnpj == "00000000000000" ||
    cnpj == "11111111111111" ||
    cnpj == "22222222222222" ||
    cnpj == "33333333333333" ||
    cnpj == "44444444444444" ||
    cnpj == "55555555555555" ||
    cnpj == "66666666666666" ||
    cnpj == "77777777777777" ||
    cnpj == "88888888888888" ||
    cnpj == "99999999999999" )
    return false;

  // Valida DVs
  let tamanho: number = cnpj.length - 2
  let numeros: any = cnpj.substring( 0, tamanho );
  let digitos: string = cnpj.substring( tamanho );
  let soma: number = 0;
  let pos: number = tamanho - 7;
  for ( let i: number = tamanho; i >= 1; i-- ) {
    soma += numeros.charAt( tamanho - i ) * pos--;
    if ( pos < 2 )
      pos = 9;
  }

  let resultado: number = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if ( resultado != parseInt( digitos.charAt( 0 ) ) )
    return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring( 0, tamanho );
  soma = 0;
  pos = tamanho - 7;
  for ( let i: number = tamanho; i >= 1; i-- ) {
    soma += numeros.charAt( tamanho - i ) * pos--;
    if ( pos < 2 )
      pos = 9;
  }

  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if ( resultado != parseInt( digitos.charAt( 1 ) ) )
    return false;

  return true;

}

const limparMascara = function ( valor: string ) {
  return valor.replace( /[^\d]+/g, '' )
}

const scorePassword = function ( pass: string ): number {
  let score: number = 0;
  if ( !pass )
    return score;

  // award every unique letter until 5 repetitions
  let letters: any = new Object();
  for ( let i = 0; i < pass.length; i++ ) {
    letters[pass[i]] = ( letters[pass[i]] || 0 ) + 1;
    score += 5.0 / letters[pass[i]];
  }

  // bonus points for mixing it up
  let variations: any = {
    digits: /\d/.test( pass ),
    lower: /[a-z]/.test( pass ),
    upper: /[A-Z]/.test( pass ),
    nonWords: /\W/.test( pass ),
  }

  let variationCount: number = 0;
  for ( let check in variations ) {
    variationCount += ( variations[check] == true ) ? 1 : 0;
  }
  score += ( variationCount - 1 ) * 10;

  return parseInt( score.toString() );
}

const eCPF = function TestaCPF ( CPF: string ) {

  let Soma: number = 0
  let Resto: number = 0
  let strCPF: string = limparMascara( CPF )


  if ( strCPF == '' ) return false;

  if ( strCPF.length != 11 )
    return false;

  // Elimina CNPJs invalidos conhecidos
  if ( strCPF == "00000000000" ||
    strCPF == "11111111111" ||
    strCPF == "22222222222" ||
    strCPF == "33333333333" ||
    strCPF == "44444444444" ||
    strCPF == "55555555555" ||
    strCPF == "66666666666" ||
    strCPF == "77777777777" ||
    strCPF == "88888888888" ||
    strCPF == "99999999999" )
    return false;

  Soma = 0;

  for ( let i: number = 1; i <= 9; i++ ) Soma = Soma + parseInt( strCPF.substring( i - 1, i ) ) * ( 11 - i );
  Resto = ( Soma * 10 ) % 11;

  if ( ( Resto == 10 ) || ( Resto == 11 ) ) Resto = 0;
  if ( Resto != parseInt( strCPF.substring( 9, 10 ) ) ) return false;

  Soma = 0;
  for ( let i: number = 1; i <= 10; i++ ) Soma = Soma + parseInt( strCPF.substring( i - 1, i ) ) * ( 12 - i );
  Resto = ( Soma * 10 ) % 11;

  if ( ( Resto == 10 ) || ( Resto == 11 ) ) Resto = 0;
  if ( Resto != parseInt( strCPF.substring( 10, 11 ) ) ) return false;

  return true;
}

export default {
  nome: [
    ( v: string ) =>
      v.length <= 40 || 'Nome Extenso! Abrevie. Máximo de 40 caracteres.'
  ],
  email: [
    ( v: string ) =>
      /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/.test(
        v
      ) || 'E-mail deve ser válido!'
  ],
  login: [
    ( v: string ) =>
      ( !!v && /^[0-9@._A-Za-z]{03,}$/.test( v ) && v.length <= 30 ) ||
      'Login de Letras e Numeros a partir de 03 até 30 caracteres'
  ],
  senha: [
    ( v: string ) =>
      ( !!v &&
        /^[a-zA-Z0-9#@$!*](_(?!(\.|_))|\.(?!(_|\.))|[a-zA-Z0-9#@$!*])[a-zA-Z0-9#@$!*]{4,23}$/.test(
          v
        ) && scorePassword( v ) >= 50 ) ||
      'Mínimo 6 caracteres. Números e Letras. Maiúsculas e Minúsculas. Não sequenciais.'
  ],
  naoVazio: [( v: string ) => !!v || 'Informação Obrigatória!'],
  foneFixoCel: [
    ( v: string ) =>
      !v ||
      v.length === 11 ||
      v.length === 10 ||
      'Telefone deve Fixo ou Celular!'
  ],
  foneCelular: [
    ( v: string ) => !v || v.length === 11 || 'Fone Celular deve ser completo!'
  ],
  cep: [( v: string ) => !v || v.length === 8 || 'CEP deve ser completo!'],
  endereco: [
    ( v: string ) =>
      v.length <= 50 || 'Endereço Extenso! Abrevie. Máximo de 50 caracteres.'
  ],
  uf: [
    ( v: string ) =>
      ( ufs.indexOf( v ) >= 0 && v.trim().length === 2 ) || 'UF Inválido!'
  ],
  complemento: [
    ( v: string ) =>
      v.length <= 30 || 'Complemento Extenso! Abrevie. Máximo de 30 caracteres.'
  ],
  bairro: [
    ( v: string ) =>
      v.length <= 35 || 'Bairro Extenso! Abrevie. Máximo de 35 caracteres.'
  ],
  cidade: [
    ( v: string ) =>
      v.length <= 35 || 'Cidade Extensa! Abrevie. Máximo de 35 caracteres.'
  ],
  getUFs: ufs,
  sgIdUsuario: [
    ( v: string ) =>
      ( !!v && /^[0-9@._A-Za-z]$/.test( v ) && v.length <= 30 ) ||
      'Login de Letras e Numeros até 30 caracteres'
  ],

  horaMinutoValida: [
    ( v: string ) =>
      ( !!v && /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])?$/.test( v ) && v.length == 5 && v < '24:00' ) ||
      'Hora Inválida'
  ],

  horaMinutoSegundoValida: [
    ( v: string ) =>
      ( !!v && /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test( v ) && v.length == 8 && v < '24:00:00' ) ||
      'Hora Inválida'
  ],

  mesAno: [( v: string ) =>
    !v || mesAnoValido( v ) || 'Mês / Ano Inválido'],

  cnpj: [( v: string ) =>
    !v || eCNPJ( v ) || 'CNPJ Inválido'],

  cpf: [( v: string ) =>
    !v || eCPF( v ) || 'CPF Inválido'],

  dataValida: [( v: string ) => !v || Utils.dataValida( v ) || "Data Inválida"]


}
