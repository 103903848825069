




















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { ClienteAttributes, ClienteContatoAttributes, ClienteUsuarioAttributes, ClienteAcompanhamentoAttributes } from '@/interfaces/backend/ModelAttributesInterface'

import { LayOutInterface } from '@/interfaces/LayOutInterface'
import { ClsCrudVue } from '../ClsCrudVue'

import ClsApollo from '../../../utils/ClsApollo'
import Utils from '../../../utils/Utils'
import Bluebird from 'bluebird'

import DataTableResponsive, { cabecalhoInterface } from '@/includes/auxForms/DataTableResponsive.vue'
import { DTFORMAT } from '../../../includes/config/DataTypesAplicacao'
import { GETTERS } from '../../../store/mutation-types'

interface frequenciaQueryInterface {
  frequencia: string
  data: string
  curso: string
  instrutor: string
  inicio: string
  termino: string
}

@Component( { components: { DataTableResponsive } } )
export default class ClienteExibirFrequencia extends Vue {

  private cabecalhos: Array<cabecalhoInterface> = [
    {
      text: 'Freq',
      align: 'left',
      value: 'frequencia'
    },
    {
      text: 'Data',
      align: 'left',
      value: 'data'
    },
    {
      text: 'Curso',
      align: 'left',
      value: 'curso'
    },
    {
      text: 'Instrutor',
      align: 'left',
      value: 'instrutor'
    },
    {
      text: 'Inicio',
      align: 'left',
      value: 'inicio'
    },
    {
      text: 'Termino',
      align: 'left',
      value: 'termino'
    }]

  @Prop()
  //@ts-ignore
  private idCliente: number

  @Prop()
  //@ts-ignore
  private layout: LayOutInterface

  @Prop()
  //@ts-ignore
  private clsCrudVue: ClsCrudVue

  @Prop( {
    default: 1
  } )
  //@ts-ignore
  private keyRefresh: number

  private model: Array<frequenciaQueryInterface> = this.resetModel()

  @Watch( 'idCliente' )
  private onWatchIdCliente () {
    this.refresh()
  }

  @Watch( 'keyRefresh' )
  private onWatchKey () {
    this.refresh()
  }

  private mounted (): void {
    this.refresh()
  }

  private resetModel (): Array<frequenciaQueryInterface> {
    return []
  }

  private refresh () {
    if ( this.idCliente != 0 )
      this.obterDadosParaModel( this.idCliente )
  }

  private obterDadosParaModel ( idCliente: number ): Bluebird<any> {

    this.model = this.resetModel()

    let clsApollo: ClsApollo = new ClsApollo()

    const sql = `
      query {
        listRaw(inputListRaw: {
          sql: "SELECT IF(ch.presente,'Sim','Não') AS frequencia, data, cr.descricao AS curso, us.nome AS instrutor,inicio, termino FROM chamadas AS ch  INNER JOIN clientes AS cl  ON ch.idCliente = cl.idCliente INNER JOIN cursos AS cr ON ch.idCurso = cr.idCurso INNER JOIN usuarios AS us ON ch.idInstrutor = us.idUsuario WHERE cl.idCliente = :IDCLIENTE ORDER BY ch.data desc, ch.inicio;",
          replacements: {
            IDCLIENTE: ${this.idCliente}
          }
        })
      }
    `

    return clsApollo
      .apolloQuery( this, sql, "listRaw", false, "no-cache" )
      .then( ( resultQuery: Array<frequenciaQueryInterface> ) => {
        if ( resultQuery.length > 0 ) {
          this.model = resultQuery.map( v => {
            let retorno: frequenciaQueryInterface = { ...v }
            retorno.data = Utils.converterData( <string>v.data, DTFORMAT.UTC, DTFORMAT.USUARIO )
            return retorno
          } )
        }
      } )

  }

}

