import { emDESENVOLVIMENTO } from "./setupDesenvolvimento"

// TODO - ALTERAR EM PRODUCAO - e ALTERAR database.json

/*
--> Dentro do DATABASE.JSON alterar o production para producao em testes.....
*/

const hostBACKEND = emDESENVOLVIMENTO ? 'localhost' : 'crm.fleekcursos.com.br'
//const hostBACKEND = '192.168.1.127'

// const pathCERTIFICADO = '/etc/letsencrypt/live/'.concat( hostBACKEND ).concat( '/' )
const pathCERTIFICADO = '/etc/letsencrypt/live/app.forhealth.com.br/'

const portBACKEND = emDESENVOLVIMENTO ? 4011 : 4009

const ssl = !emDESENVOLVIMENTO

export const configBACKEND =
{
  ssl: ssl,
  port: portBACKEND,
  hostname: hostBACKEND,
  httpEndpoint: 'http'.concat( emDESENVOLVIMENTO ? '' : 's' ).concat( '://' ).concat( hostBACKEND ).concat( ':' ).concat( portBACKEND.toString() ).concat( '/graphql' ),
  pathCERTIFICADO: pathCERTIFICADO
}

