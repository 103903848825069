



















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ClsCrudVue } from '@/components/crud/ClsCrudVue';
import { THEME } from '../../plugins/vuetify';

export interface rsMenuInterface {
  descricao: string
  permissao: boolean
  nomeIcone: string
  link: string
}

@Component
export default class MenuRelatorios extends Vue {
  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this )

  private THEME = THEME

  private rsMenu: Array<rsMenuInterface> = [{
    descricao: 'Novo Cliente',
    permissao: this.clsCrudVue.PERMISSOES.CLIENTE.PERMISSAO.CREATE,
    nomeIcone: 'MenuCliente',
    link: '/NovoCliente'
  },
  {
    descricao: 'Pesquisar Clientes',
    permissao: this.clsCrudVue.PERMISSOES.CLIENTE.PERMISSAO.UPDATE,
    nomeIcone: 'ClientePesquisa',
    link: '/ClientePesquisa'
  },
  {
    descricao: 'Quadro de Horários',
    permissao: this.clsCrudVue.PERMISSOES.CLIENTEACOMPANHAMENTO.PERMISSAO.CREATE,
    nomeIcone: 'RelQuadroHorario',
    link: '/RelQuadroHorario'
  },
  {
    descricao: 'Reposições',
    permissao: this.clsCrudVue.PERMISSOES.CLIENTEACOMPANHAMENTO.PERMISSAO.CREATE,
    nomeIcone: 'RelQuadroHorario',
    link: '/Reposicao'
  }
  ]

}

