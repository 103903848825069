































import { Component, Prop, Vue } from 'vue-property-decorator'
import { ClsCrudVue } from '@/components/crud/ClsCrudVue'

import { LayOutInterface } from '@/interfaces/LayOutInterface'
import MensagemForm from '@/includes/auxForms/MensagemForm.vue'
import Loading from '@/includes/auxForms/Loading.vue'
import ErroWs from '@/includes/auxForms/ErroWs.vue'

import store from "@/store"
import { stateRootInterface } from '@/interfaces/StoreInterface'
import { Store } from 'vuex'
import Logger from '@/utils/Logger'
import Utils from '@/utils/Utils'

import MenuHome from './MenuHome.vue'

@Component( {
  components: {
    MensagemForm,
    Loading,
    ErroWs,
    MenuHome
  }
} )

export default class Home extends Vue {

  private get store (): Store<stateRootInterface> {
    return store
  }

  private mounted (): void {

  }

  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this )
  private layout: LayOutInterface = this.clsCrudVue.layOutInicial

}
