import store from '@/store';
import { LOGAR_MUTATION, LOGOUT_MUTATION, PERMISSOES_SETAR_MUTATION } from '@/store/mutation-types';
import ClsState from '@/utils/ClsState';
import ClsApollo from '@/utils/ClsApollo';
import { respostaPadraoInterface } from '@/interfaces/backend/padrao.interfaces';
import { rsUsuarioLogadoInterface } from '@/interfaces/backend/usuario.interfaces';
import * as Bluebird from 'bluebird';
import { permissoesInterface } from '@/interfaces/backend/permissao.interfaces';

export default class ClsLogin {
  private self: any

  public constructor( self: any ) {
    this.self = self
  }

  private setarPermissoes (): Bluebird<boolean> {

    return new Bluebird<boolean>( ( resolve: any, reject: any ) => {
      let clsApollo: ClsApollo = new ClsApollo()

      const sqlPermissoes = `
      query {
        getPermissoes 
      }
      `
      clsApollo.apolloQuery( this.self, sqlPermissoes, 'getPermissoes' ).then( ( resultgetPermissoes: permissoesInterface ) => {
        store.commit( 'permissoes/'.concat( PERMISSOES_SETAR_MUTATION ), resultgetPermissoes )
        resolve( true )
      } ).catch( ( err: any ) => {
        reject( false )
      } )
    } )

  }

  public logarPorToken (): Bluebird<rsUsuarioLogadoInterface> {
    return new Bluebird<rsUsuarioLogadoInterface>( ( resolve: any, reject: any ) => {

      let clsApollo: ClsApollo = new ClsApollo()
      let clsState: ClsState = new ClsState()

      const sqlLogin = `
        mutation {
          logarPorToken {
            ok
            mensagem
            nome
            token
            idUsuario
          }
        }
      `

      clsState.setTokenStateFromLocalStorage()

      return clsApollo.apolloMutation( this.self, sqlLogin, 'logarPorToken' ).then( ( resultLoginPorToken: rsUsuarioLogadoInterface ) => {

        if ( resultLoginPorToken.ok ) {
          store.commit( 'login/'.concat( LOGAR_MUTATION ), resultLoginPorToken )
          return this.setarPermissoes().then( ( resultSetarPermissoes: boolean ) => {
            resolve( resultLoginPorToken )
          } )
        } else {
          resolve( resultLoginPorToken )
        }

      } )

    } )

  }

  public logout (): Bluebird<respostaPadraoInterface> {
    let clsApollo: ClsApollo = new ClsApollo()
    let clsState: ClsState = new ClsState()

    const sqlLogout = `

      mutation {
        logout {
          ok
          mensagem
        }
      }
    `

    return new Bluebird<respostaPadraoInterface>( ( resolve: any, reject: any ) => {
      return clsApollo.apolloMutation( this.self, sqlLogout, 'logout' ).then( ( resultLogout: respostaPadraoInterface ) => {
        store.commit( 'login/'.concat( LOGOUT_MUTATION ) )
        clsState.resetTokenFromLocalStorage()
        resolve( resultLogout )
      } )
    } )

  }

  public logar ( login: string, senha: string ): Bluebird<rsUsuarioLogadoInterface> {

    let retorno: rsUsuarioLogadoInterface = {
      ok: false,
      mensagem: 'Erro no Login!',
      nome: '',
      token: ''
    }

    return new Bluebird<rsUsuarioLogadoInterface>( ( resolve: any, reject: any ) => {

      let clsApollo: ClsApollo = new ClsApollo()

      const sqlLogin = `

      mutation {
        login(inputUsuarioLogin: {
          login: "${login}",
          senha: "${senha}"    
        }) {
          ok,
          mensagem,
          nome,
          token,
          idUsuario
        }
      }
    `

      clsApollo.apolloMutation( this.self, sqlLogin, 'login' ).then( ( resultLogin: rsUsuarioLogadoInterface ) => {

        if ( resultLogin.ok ) {
          store.commit( 'login/'.concat( LOGAR_MUTATION ), resultLogin )
          return this.setarPermissoes().then( ( resultSetarPermissoes: boolean ) => {
            resolve( resultLogin )
          } )

        } else {
          resolve( retorno )
        }

      } )

    } )

  }
}