import { Module } from "vuex";
import {
  LOGAR_MUTATION,
  LOGOUT_MUTATION,
  SETTOKEN_MUTATION
} from "@/store/mutation-types";
import { stateLoginInterface, stateRootInterface } from '@/interfaces/StoreInterface';
import { rsUsuarioLogadoInterface } from '@/interfaces/backend/usuario.interfaces';
import Logger from "@/utils/Logger";

const getters = {
};

const state: stateLoginInterface = {
  logado: false,
  token: "",
  nome: "",
  idUsuario: 0
};

const mutations = {
  [LOGAR_MUTATION] (
    stateAtual: stateLoginInterface,
    payload: rsUsuarioLogadoInterface
  ) {
    stateAtual.logado = payload.ok
    stateAtual.token = payload.token ? payload.token : ""
    stateAtual.nome = payload.ok ? payload.nome : ""
    stateAtual.idUsuario = payload.idUsuario ? payload.idUsuario : 0
  },

  [LOGOUT_MUTATION] ( stateAtual: stateLoginInterface ) {
    stateAtual.logado = false
    stateAtual.nome = ""
    stateAtual.token = ""
  },

  [SETTOKEN_MUTATION] ( stateAtual: stateLoginInterface, token: string ) {
    stateAtual.token = token;
  }

}

const actions = {}

export const LoginStore: Module<stateLoginInterface, stateRootInterface> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
