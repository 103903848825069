















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import MensagemForm from '@/includes/auxForms/MensagemForm.vue'
import Loading from '@/includes/auxForms/Loading.vue'
import ErroWs from '@/includes/auxForms/ErroWs.vue'
import ConfirmDialog from '@/includes/auxForms/ConfirmDialog.vue'
import { LayOutInterface } from '@/interfaces/LayOutInterface'
import { ClsCrudVue } from '../ClsCrudVue'
import ClsReposicao, { reposicoesInterface } from './ClsReposicao'
import DataTableResponsive, { cabecalhoInterface, acoesInterface } from '../../../includes/auxForms/DataTableResponsive.vue'

import _ from 'lodash'

@Component( {
  components: {
    MensagemForm,
    Loading,
    ErroWs,
    ConfirmDialog,
    DataTableResponsive
  }
} )

export default class Crud extends Vue {

  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this, 'MeusClientes' )

  private layout: LayOutInterface = this.clsCrudVue.layOutInicial

  private clsReposicao: ClsReposicao = new ClsReposicao( this )

  private mounted (): void {
    this.pesquisarReposicoes()
  }

  private pesquisarReposicoes (): void {
    this.clsReposicao.pesquisarReposicoes()
  }

  private btExcluir ( item: any ) {

    this.clsCrudVue.confirmarExclusao( {
      // @ts-ignore
      valorId: item.idClienteCurso,
      campoChave: 'idClienteCurso',
      tabela: 'ClienteCurso',
      // @ts-ignore
      msgAviso: 'Excluir '.concat( item.nome ).concat( '?' ),
      fnPosMutationOK: this.pesquisarReposicoes
    } )

  }

  private acoes: Array<acoesInterface> = [
    {
      icone: 'CrudExcluir',
      evento: 'btExcluir',
      tooltip: 'Excluir'
    }]

  private cabecalhos: Array<cabecalhoInterface> = [
    {
      text: 'Dia',
      align: 'left',
      value: 'diaSemana'
    },
    {
      text: 'Curso',
      align: 'left',
      value: 'curso'
    },
    {
      text: 'Cliente',
      align: 'left',
      value: 'nome'
    },
    {
      text: 'Instrutor',
      align: 'left',
      value: 'instrutor'
    },
    {
      text: 'Inicio',
      align: 'left',
      value: 'inicio'
    },
    {
      text: 'Término',
      align: 'left',
      value: 'termino'
    }]

  private btFechar (): void {
    this.$router.push( '/Home' )
  }

}
