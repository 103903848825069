



















































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { LayOutInterface } from '../../../interfaces/LayOutInterface'
import { ClsCrudVue } from '../ClsCrudVue'
import { TiposContatoType } from '../../../includes/config/typeTiposContato'
import { ClienteContatoAttributes } from '../../../interfaces/backend/ModelAttributesInterface'

import * as _ from 'lodash'

import DataTableResponsive, { cabecalhoInterface, acoesInterface } from '@/includes/auxForms/DataTableResponsive.vue'

// @ts-ignore
import { mask } from 'vue-the-mask'
import Logger from '../../../utils/Logger'

@Component( {
  directives: {
    mask
  },
  components: {
    DataTableResponsive
  }
} )
export default class Crud extends Vue {

  @Prop()
  //@ts-ignore
  private layout: LayOutInterface

  @Prop()
  //@ts-ignore
  private clsCrudVue: ClsCrudVue

  @Prop()
  //@ts-ignore
  private self: any

  @Prop()
  //@ts-ignore
  private items: Array<ClienteContatoAttributes>

  @Watch( 'items' )
  private onChangeItems ( newValue: Array<ClienteContatoAttributes>, oldValue: Array<ClienteContatoAttributes> ) {
    this.rsContatos = newValue
  }

  private descricaoContato: string = ''
  private tipoContato: string = ''

  private rsContatos: Array<ClienteContatoAttributes> = []

  private btEditarContato ( contato: ClienteContatoAttributes ) {
    _.remove( this.rsContatos, ( v ) => {
      let retorno: boolean = false
      if ( v.descricao == contato.descricao && v.tipo == contato.tipo ) {
        this.tipoContato = <string>v.tipo
        this.descricaoContato = <string>v.descricao
        retorno = true
      }

      return retorno
    } )

    this.$emit( 'onChange', this.rsContatos )
  }

  private btIncluirContato () {
    let objeto: object = {
      descricao: this.descricaoContato,
      tipo: this.tipoContato
    }

    if ( _.findIndex( this.rsContatos, objeto ) < 0 ) {
      this.rsContatos.push( objeto )
      this.$emit( 'onChange', this.rsContatos )
    }

    this.btCancelarContato()

  }

  private btCancelarContato (): void {
    this.descricaoContato = ''
    this.tipoContato = ''
    this.keyRefreshDescricaoContato++
    this.keyRefreshTipoContato++
  }

  private get rsItensTipoContato (): Array<string> {
    return TiposContatoType.map( v => v.descricao )
  }

  private get maskTipoContato (): string | Array<string> {
    let indice: number = _.findIndex( TiposContatoType, { descricao: this.tipoContato } )
    return indice >= 0 ? TiposContatoType[indice].mascara : ''
  }

  @Watch( 'tipoContato' )
  private onChangeTipoContato ( newValue: string, oldValue: string ) {
    this.keyRefreshDescricaoContato++
  }

  @Watch( 'descricaoContato' )
  private onChangeDescricaoContato ( newValue: string, oldValue: string ) {
    this.keyRefreshTipoContato++
  }

  private keyRefreshDescricaoContato: number = 0
  private keyRefreshTipoContato: number = 0

  private validarContato ( valor: string ): boolean | string {
    let retorno: boolean | string = ( ( !this.descricaoContato || this.descricaoContato.length == 0 ) && ( !this.tipoContato || this.tipoContato.length == 0 ) )

    if ( !retorno && ( this.descricaoContato && this.descricaoContato.length > 0 && this.tipoContato && this.tipoContato.length > 0 ) ) {
      retorno = 'Adicione para Confirmar.'
    }

    return retorno ? retorno : 'Forneça Tipo e Contato.'
  }

}

