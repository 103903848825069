





























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ClsCrudVue } from '../crud/ClsCrudVue';
import { THEME } from '../../plugins/vuetify';

@Component
export default class MenuRelatorios extends Vue {
  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this )

  private THEME = THEME

}

