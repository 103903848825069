













































import { Component, Prop, Vue } from 'vue-property-decorator'

import { LayOutInterface } from '@/interfaces/LayOutInterface'
import MensagemForm from '@/includes/auxForms/MensagemForm.vue'
import Loading from '@/includes/auxForms/Loading.vue'
import ErroWs from '@/includes/auxForms/ErroWs.vue'

import Utils from '@/utils/Utils'
import { ClsCrudVue } from '@/components/crud/ClsCrudVue'
import ClsState from '@/utils/ClsState'
import ClsLogin from './ClsLogin'
import { emDESENVOLVIMENTO } from '../../includes/config/setupDesenvolvimento'
import { respostaPadraoInterface } from '../../interfaces/backend/padrao.interfaces'

import store from '@/store'
import { CADASTROS_MUTATION_REFRESH_ALL } from '../../store/mutation-types'

@Component( {
  components: {
    MensagemForm,
    Loading,
    ErroWs,
  },
} )
export default class Logar extends Vue {

  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this )

  private clsState: ClsState = new ClsState()
  private clsLogin: ClsLogin = new ClsLogin( this )

  private permanecerConectado: boolean = true

  private layout: LayOutInterface = this.clsCrudVue.layOutInicial

  private layoutLocal = {
    exibirForm: false,
    exibirSenha: false
  }

  private model = {
    login: '',
    senha: '',
  }

  private emDesenvolvimento = emDESENVOLVIMENTO

  private mounted (): void {

    this.layoutLocal.exibirForm = false

    this.clsLogin
      .logarPorToken()
      .then( ( resultLogarPorToken: respostaPadraoInterface ) => {
        if ( !resultLogarPorToken.ok ) {

          if ( this.emDesenvolvimento ) {

            this.model.login = 'zanatta'
            this.model.senha = 'Teste123'
            //this.model.login = 'manoela'
            //this.model.senha = 'People2020'
          }

          this.layoutLocal.exibirForm = true

        } else {

          this.clsState.saveTokenToLocalStorage()

          this.redirAposLogin()

        }
      } )
  }

  private btLogar (): void {

    this.clsLogin
      .logar( this.model.login.trim(), this.model.senha.trim() )
      .then( ( resultLogar: respostaPadraoInterface ) => {
        if ( !resultLogar.ok ) {
          this.layout.exibirLoading = false
          this.layout.mensagem = resultLogar.mensagem
          this.layout.exibirMensagem = true
        } else {
          if ( this.permanecerConectado ) {
            this.clsState.saveTokenToLocalStorage()
          } else {
            this.clsState.resetTokenFromLocalStorage()
          }

          this.redirAposLogin()

        }
      } )
  }

  private redirAposLogin () {

    store.commit( 'cadastros/'.concat( CADASTROS_MUTATION_REFRESH_ALL ), this )

    if ( this.emDesenvolvimento ) {
      //this.$router.push( '/CrudUsuarios' )
      //this.$router.push( '/CrudClientes' )
      // this.$router.push( '/Reposicao' )
      // this.$router.push( '/Testes' )
      // this.$router.push( '/NovoCliente' )
      this.$router.push( '/ClientePesquisa' )
      //this.$router.push( '/RelQuadroHorario' )
    } else {
      // this.$router.push( '/ClientePesquisa' )
      this.$router.push( '/Home' )
    }
  }

  private fullScreen (): void {
    Utils.toggleFullScreen()
  }
}
