import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/home/Home.vue'
import Logar from '@/components/login/Logar.vue'
import Logout from '@/components/login/Logout.vue'
import CrudGrupo from '@/components/crud/grupos.crud.vue'
import CrudUsuario from '@/components/crud/usuarios.crud.vue'
import CrudCliente from '@/components/crud/clientes/clientes.crud.vue'
import NovoCliente from '@/components/crud/clientes/novocliente.vue'
import ClientePesquisa from '@/components/crud/clientes/cliente.pesquisa.vue'
import MeusClientes from '@/components/crud/clientes/meusclientes.vue'
import AlterarPropriaSenha from '@/components/usuario/AlterarPropriaSenha.vue'
import Testes from '@/testes/testes.vue'

import RelQuadroHorario from '@/views/relatorios/RelQuadroHorario.vue'
import Reposicao from '@/components/crud/reposicao/reposicao.crud.vue'

import * as _ from 'lodash'

import store from '../store'
import { emDESENVOLVIMENTO } from '@/includes/config/setupDesenvolvimento'

Vue.use( VueRouter )

const rotasLiberadasSemLogin = ['/Testes', '/Logar']

const rotasRelatorios: any = [
  {
    path: '/RelQuadroHorario',
    name: 'RelQuadroHorario',
    component: RelQuadroHorario
  },
  {
    path: '/Reposicao',
    name: 'Reposicao',
    component: Reposicao
  }
]

const rotasUsuarios: any = [
  {
    path: '/AlterarPropriaSenha',
    name: 'AlterarPropriaSenha',
    component: AlterarPropriaSenha
  }
]

const rotasPadrao: any = [
  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: '/Logar',
    name: 'Logar',
    component: Logar
  },
  {
    path: '/Logout',
    name: 'Logout',
    component: Logout
  }
]

const rotasCrud: any = [
  {
    path: '/CrudGrupos',
    name: 'CrudGrupos',
    component: CrudGrupo
  },
  {
    path: '/CrudUsuarios',
    name: 'CrudUsuarios',
    component: CrudUsuario
  },
  {
    path: '/CrudClientes',
    name: 'CrudClientes',
    component: CrudCliente
  },
  {
    path: '/NovoCliente',
    name: 'NovoCliente',
    component: NovoCliente
  },
  {
    path: '/MeusClientes',
    name: 'MeusClientes',
    component: MeusClientes
  },
  {
    path: '/ClientePesquisa',
    name: 'ClientePesquisa',
    component: ClientePesquisa
  }

]

const rotasEmDesenvolvimento: any = [
  {
    path: '/Testes',
    name: 'Testes',
    component: Testes
  }
]

const router = new VueRouter( {
  routes: rotasPadrao.concat( rotasRelatorios ).concat( rotasCrud ).concat( rotasUsuarios ).concat( emDESENVOLVIMENTO ? rotasEmDesenvolvimento : [] )
} )

router.beforeEach( ( to, from, next ) => {
  if ( !store.state.login.logado && _.indexOf( rotasLiberadasSemLogin, to.path ) < 0 ) {
    next( '/Logar' )
  } else {
    next()
  }
} )

export default router
