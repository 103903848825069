import { Module } from 'vuex'
import { CADASTROS_MUTATION_REFRESH_ALL, CADASTROS_MUTATION_REFRESH_CLIENTES } from './mutation-types'

import * as _ from 'lodash'
import { stateRootInterface, stateCadastrosInterface } from '@/interfaces/StoreInterface'

import ClsCadastrosStore from './ClsCadastrosStore'
import { CanalAttributes, StatusAttributes, UsuarioAttributes, EtiquetaAttributes, CursoCategoriaAttributes, CursoAttributes } from '@/interfaces/backend/ModelAttributesInterface'

const getters = {
  Canal: ( state: stateCadastrosInterface ) => ( idCanal: number ) => {
    let retorno: CanalAttributes | undefined = _.find( state.rsCanais, { 'idCanal': idCanal } )
    return retorno ? retorno : {}
  },

  Status: ( state: stateCadastrosInterface ) => ( idStatus: number ) => {
    let retorno: StatusAttributes | undefined = _.find( state.rsStatus, { 'idStatus': idStatus } )
    return retorno ? retorno : {}
  },

  Usuario: ( state: stateCadastrosInterface ) => ( idUsuario: number ) => {
    let retorno: UsuarioAttributes | undefined = _.find( state.rsUsuarios, { 'idUsuario': idUsuario } )
    return retorno ? retorno : {}
  },

  Etiqueta: ( state: stateCadastrosInterface ) => ( idEtiqueta: number ) => {
    let retorno: EtiquetaAttributes | undefined = _.find( state.rsEtiquetas, { 'idEtiqueta': idEtiqueta } )
    return retorno ? retorno : {}
  },

  CursoCategoria: ( state: stateCadastrosInterface ) => ( idCursoCategoria: number ) => {
    let retorno: CursoCategoriaAttributes | undefined = _.find( state.rsCursosCategorias, { 'idCursoCategoria': idCursoCategoria } )
    return retorno ? retorno : {}
  },

  Curso: ( state: stateCadastrosInterface ) => ( idCurso: number ) => {
    let retorno: CursoAttributes | undefined = _.find( state.rsCursos, { 'idCurso': idCurso } )
    return retorno ? retorno : {}
  }

}

const state: stateCadastrosInterface = {
  rsBairros: [],
  rsCanais: [],
  rsCidades: [],
  rsCursos: [],
  rsCursosCategorias: [],
  rsEtiquetas: [],
  rsStatus: [],
  rsUsuarios: []
}

const mutations = {

  [CADASTROS_MUTATION_REFRESH_ALL] (
    stateAtual: stateCadastrosInterface,
    thisPai: any
  ) {

    let clsCadatrosStore: ClsCadastrosStore = new ClsCadastrosStore()

    clsCadatrosStore.refreshAll( thisPai ).then( ( rsAll: stateCadastrosInterface ) => {
      stateAtual.rsBairros = rsAll.rsBairros
      stateAtual.rsCanais = rsAll.rsCanais
      stateAtual.rsCidades = rsAll.rsCidades
      stateAtual.rsCursos = rsAll.rsCursos
      stateAtual.rsCursosCategorias = rsAll.rsCursosCategorias
      stateAtual.rsEtiquetas = rsAll.rsEtiquetas
      stateAtual.rsStatus = rsAll.rsStatus
      stateAtual.rsUsuarios = rsAll.rsUsuarios
    } )

  },

  [CADASTROS_MUTATION_REFRESH_CLIENTES] (
    stateAtual: stateCadastrosInterface,
    thisPai: any
  ) {

    let clsCadatrosStore: ClsCadastrosStore = new ClsCadastrosStore()

    clsCadatrosStore.refreshClientes( thisPai ).then( ( rsAll: any ) => {
      stateAtual.rsBairros = rsAll.rsBairros
      stateAtual.rsCidades = rsAll.rsCidades
    } )

  }

}

const actions = {

}

export const CadastrosStore: Module<stateCadastrosInterface, stateRootInterface> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
