








































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { LayOutInterface } from '@/interfaces/LayOutInterface'
import { ClsCrudVue } from '../ClsCrudVue'

import Logger from '../../../utils/Logger'
import ClsApollo from '../../../utils/ClsApollo'
import Utils from '../../../utils/Utils'

import _ from 'lodash'
import { ClienteAttributes, ClienteContatoAttributes } from '../../../interfaces/backend/ModelAttributesInterface'

@Component
export default class Crud extends Vue {

  @Prop()
  //@ts-ignore
  private rsCliente: ClienteAttributes

  @Prop()
  //@ts-ignore
  private rsClienteContatos: Array<ClienteContatoAttributes>

  @Prop()
  //@ts-ignore
  private rsClienteEtiquetas: Array<ClienteContatoAttributes>

  @Prop()
  //@ts-ignore
  private rsClienteUsuarios: Array<ClienteUsuarioAttributes>

  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this, 'ExibirCadastroCliente' )
  /*
    private getStatus ( idStatus: number ): string {
      let status: any = _.find( this.clsCrudVue.state.cadastros.rsStatus, { 'idStatus': idStatus } )
      return status ? status.descricao : ""
    }
  */

}

