import * as isMobile from 'is-mobile'
import * as _ from 'lodash'

import * as moment from 'moment'
import { DTFORMAT } from '@/includes/config/DataTypesAplicacao'
import { disableExperimentalFragmentVariables } from 'graphql-tag'

class Utils {
  public get isMobileLandScape (): boolean {
    // return ( window.innerHeight <= 420 && window.innerWidth > window.innerHeight )
    let retorno: boolean = this.isMobile && ( window.innerHeight < 768 && window.innerWidth >= window.innerHeight )

    return retorno
  }

  public get isMobile (): boolean {
    /*
        let retorno: boolean = ( window.innerHeight < 768 && window.innerWidth >= window.innerHeight )
          || ( window.innerWidth < 768 && window.innerHeight >= window.innerWidth )
    */
    return isMobile.isMobile()
  }

  public get width (): number {
    return window.innerWidth
  }

  public get height (): number {
    return window.innerHeight
  }

  /**
   * Converte em formato gql retirando a propriedade __typename caso exista
   * @param Objeto 
   */
  public ConverterEmGql ( Objeto: Object ): String {

    const converterArray = ( val: Array<any> ) => {
      let retorno: string = ""
      if ( val.length > 0 && _.isObject( val[0] ) ) {
        let novoArray: any = []
        _.forEach( val, function ( valor: any, chave: any ) {
          //@ts-ignore
          novoArray.push( this.ConverterEmGql( valor ) )
        }.bind( this ) )

        retorno += '[' + novoArray + ']'

      } else {
        retorno += '[' + val + ']'
      }
      return retorno
    }

    let retorno: String = ""

    if ( _.isArray( Objeto ) ) {

      //@ts-ignore
      retorno = converterArray( Objeto )

    } else {

      retorno = '{'

      for ( let [key, val] of Object.entries( Objeto ) ) {

        if ( key !== "__typename" ) {

          retorno += key
          retorno += ':'

          if ( _.isString( val ) ) {
            //retorno += '"' + val + '"'
            retorno += JSON.stringify( val )
          } else if ( _.isArray( val ) ) {
            retorno += converterArray( val )
          } else {
            retorno += val
          }

          retorno += '\n'
        }

      }

      retorno += '}'

    }


    return retorno
  }

  public toggleFullScreen (): void {

    let doc: any = window.document
    let docEl: any = doc.documentElement

    let requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen
    let cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen

    if ( !doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement ) {
      requestFullScreen.call( docEl )
    }
    else {
      cancelFullScreen.call( doc )
    }
  }

  public filtrarSemAcentos ( item: object, queryText: string, itemText: string ): boolean {

    const limparAcentos = ( str: string ): string => {
      if ( !str ) return ''
      str = str.toLowerCase()
      str = str.replace( /[aáàãäâ]/, 'a' )
      str = str.replace( /[eéèëê]/, 'e' )
      str = str.replace( /[iíìïî]/, 'i' )
      str = str.replace( /[oóòõöô]/, 'o' )
      str = str.replace( /[uúùüû]/, 'u' )
      return str
    }

    if ( !queryText || queryText === '' ) {
      return true;
    }
    var n = limparAcentos( itemText );
    var s = limparAcentos( queryText );
    return n.includes( s );
  }

  public converterDataParaBanco ( dataUsuario: string ): string {
    let retorno: string = ''

    if ( dataUsuario.length == 8 ) {
      dataUsuario = dataUsuario.substr( 0, 6 ).concat( moment().format( "YYYY" ).substr( 0, 2 ) ).concat( dataUsuario.substr( 6, 2 ) )
    }

    if ( dataUsuario.length == 10 ) {
      retorno = moment( dataUsuario, DTFORMAT.USUARIO ).format( DTFORMAT.BANCO )
    }

    return retorno

  }

  public converterData ( dataOrigem: string, formatoOrigem: string, formatoDestino: string ) {
    return moment( dataOrigem, formatoOrigem ).format( formatoDestino )
  }

  public converterDataParaUsuario ( dataBanco: string | null | undefined ): string {
    let retorno: string = ''

    if ( dataBanco ) {

      if ( dataBanco.length == 10 ) {
        retorno = moment( dataBanco, DTFORMAT.BANCO ).format( DTFORMAT.USUARIO )
      } else if ( dataBanco.length == 24 ) {
        retorno = moment( dataBanco, DTFORMAT.UTC ).format( DTFORMAT.USUARIO )
      }
    }

    return retorno

  }

  public dataValida ( data: string, formato: string = DTFORMAT.USUARIO ): boolean {
    return moment( data, formato ).isValid()
  }

  public transformarVetorEmObjetoRS ( vetor: Array<any>, nomeCampo: string ): Array<any> {

    return vetor.map( ( v ) => {
      let objRetorno: any = {}
      objRetorno[nomeCampo] = v

      return objRetorno
    } )

  }

  public transformarObjetoEmInClauseSQL ( vetor: Array<any>, propriedade: string ): string {
    return JSON.stringify( vetor.map( ( o ) => o[propriedade] ) ).replace( '[', '(' ).replace( ']', ')' )
  }

  public transformarVetorNumberEmInClauseSQL ( vetor: Array<number> ): string {
    return JSON.stringify( vetor.map( ( o ) => o ) ).replace( '[', '(' ).replace( ']', ')' )
  }

  /**
   * Verifica se todas as propriedades são nulas, vazias ou "0"
   * @param objeto 
   */
  public objetoAllPropsNulasOuVazias ( objeto: object ) {

    return !Object.values( objeto ).some( x => ( x !== null && x.length !== 0 && x !== 0 && x ) );
  }

  /**
   * True se todas as propriedades do objeto possuem valor
   * @param objeto 
   */
  public objetoAllPropsNaoVazias ( objeto: object ) {
    return !Object.values( objeto ).some( x => ( x == null || x.length == 0 || x == 0 || !x ) );
  }

  /**
   * Retorna somente os números de uma string
   * @param oque Qual string extrair números
   */
  public getSomenteNumeros ( oque: string ): string {
    let retorno = oque.match( /\d/g )
    return retorno ? retorno.join( "" ) : ''
  }

}

export default new Utils()