

























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import DataTableResponsive, { cabecalhoInterface, acoesInterface } from '@/includes/auxForms/DataTableResponsive.vue'

import MensagemForm from '@/includes/auxForms/MensagemForm.vue'
import Loading from '@/includes/auxForms/Loading.vue'
import ErroWs from '@/includes/auxForms/ErroWs.vue'
import ConfirmDialog from '@/includes/auxForms/ConfirmDialog.vue'

import { THEME } from '@/plugins/vuetify'
import ClsQuadroListagemAlunos from './ClsQuadroListagemAlunos'
import { ClsCrudVue } from '../../components/crud/ClsCrudVue'
import { LayOutInterface } from '../../interfaces/LayOutInterface'
import Logger from '../../utils/Logger'
import { ChamadaAttributes } from '../../interfaces/backend/ModelAttributesInterface'
import { eventsInterface } from './ClsRelQuadroHorarios'
import ClsApollo from '../../utils/ClsApollo'
import Utils from '../../utils/Utils'
import { respostaPadraoInterface } from '../../interfaces/backend/padrao.interfaces'

import _ from 'lodash'

interface rsExibirContatoInterface {
  nome: string
  descricao: string
  tipo: string
}

interface rsLinkInterface {
  link: string
  descricao: string
  tipo: string
}

@Component( {
  components: {
    MensagemForm,
    Loading,
    ErroWs,
    ConfirmDialog,
    DataTableResponsive
  }
} )

export default class ClsListagemAlunos extends Vue {

  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this, 'ContatosAlunos' )

  private layout: LayOutInterface = this.clsCrudVue.layOutInicial

  @Prop( {
    default: false
  } )
  //@ts-ignore
  private value: boolean

  @Watch( 'value' )
  private onChangeExibir () {
    if ( this.value ) {
      this.getContatos()
    }
  }

  @Prop()
  //@ts-ignore
  private idCliente: number

  private THEME = THEME

  private nomeAluno: string = ''

  private btFechar (): void {
    this.$emit( 'btFechar' )
  }

  private rsContatoLinks: Array<rsLinkInterface> = []

  private getContatos () {

    this.rsContatoLinks = []

    if ( this.idCliente != 0 ) {
      let clsApollo: ClsApollo = new ClsApollo()

      this.nomeAluno = ''

      const sql = `
        query {
          listRaw(inputListRaw: {
            sql: "SELECT nome,descricao,tipo FROM clientescontatos AS cc INNER JOIN clientes AS cl ON cc.idCliente = cl.idCliente WHERE cc.idCliente = :IDCLIENTE;",
            replacements: {
              IDCLIENTE: ${this.idCliente}
            }
          })
        }
      `

      return clsApollo
        .apolloQuery( this, sql, "listRaw", false, "no-cache" )
        .then( ( resultQuery: Array<rsExibirContatoInterface> ) => {
          if ( resultQuery.length > 0 ) {
            this.nomeAluno = resultQuery[0].nome

            _.forEach( resultQuery, ( contato: rsExibirContatoInterface, indice: number ) => {

              // Telefones....
              if ( contato.tipo.toUpperCase().indexOf( 'FONE' ) >= 0 ) {

                let link: string = Utils.getSomenteNumeros( contato.descricao )

                if ( link.length == 10 || link.length == 11 ) {
                  link = '0'.concat( link )
                }

                this.rsContatoLinks.push( {
                  descricao: contato.descricao,
                  link: '<a href="tel:'.concat( link ).concat( '">' ).concat( contato.descricao ).concat( '</a>' ),
                  tipo: 'Fone'
                } )

              }

              //Whats App
              if ( contato.tipo.toUpperCase().indexOf( 'WHATS' ) >= 0 ) {

                let link: string = Utils.getSomenteNumeros( contato.descricao )

                link = '+55'.concat( link )

                if ( Utils.isMobile ) {

                  this.rsContatoLinks.push( {
                    descricao: contato.descricao,
                    link: '<a target="_blank" href="https://wa.me/'.concat( link ).concat( '">' ).concat( contato.descricao ).concat( '</a>' ),
                    tipo: 'WhatsApp'
                  } )

                } else {
                  this.rsContatoLinks.push( {
                    descricao: contato.descricao,
                    link: '<a target="_blank" href="https://web.whatsapp.com/send?phone='.concat( link ).concat( '">' ).concat( contato.descricao ).concat( '</a>' ),
                    tipo: 'WhatsApp'
                  } )

                }

              }



            } )

          }
        } )

    }
  }

}

