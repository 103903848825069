import Vue from "vue"
import VueApollo from "vue-apollo"
import store from "@/store"
import { AUTH_TOKEN } from "@/utils/ClsState"
import { configBACKEND } from '@/includes/config/configServidor'
import logger from '@/utils/Logger'

// const createApolloClient = require( "vue-cli-plugin-apollo/graphql-client" )
// , restartWebsockets

import { createApolloClient, restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client'
// import { createUploadLink } from 'apollo-upload-client'

// Install the vue plugin
Vue.use( VueApollo );

// Name of the localStorage item

// Http endpoint

// const httpEndpoint = configBACKEND.httpEndpoint;
// const wsEndpoint = emDesenvolvimento ? 'wss://testes.4doctor.com.br:4000/graphql' : 'wss://testes.4doctor.com.br:4000/graphql'
// const wsEndpoint = 'ws://131.153.30.158:4000/graphql'

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint: configBACKEND.httpEndpoint,
  // link: createUploadLink( { uri: configBACKEND.httpEndpointUpload } ),
  inMemoryCacheOptions: {
    freezeResults: false
  },
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  wsEndpoint: null,

  // wsEndpoint,

  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  fetchPolicy: "no-cache",

  addTypename: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  // link: myLink

  // Override default cache
  // cache: myCache

  // Override the way the Authorization header is set
  getAuth: ( tokenName: any ) => {
    let retorno: string = "";
    if ( store && store.state && store.state.login && store.state.login.token ) {
      retorno = store.state.login.token;
    }
    return retorno;
  }

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
};

// Call this in the Vue app file
export function createProvider ( options = {} ) {
  // Create apollo client
  const { apolloClient, wsClient } = createApolloClient( {
    ...defaultOptions,
    ...options
  } );
  apolloClient.wsClient = wsClient;

  // Create vue apollo provider
  const apolloProvider = new VueApollo( {
    defaultClient: apolloClient,

    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
      }
    },
    errorHandler ( error ) {
      logger.objeto( error, "Erro do Objeto na Instalação do apolloProvider" );
      // eslint-disable-next-line no-console
      // let estilo: string = 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;'
      // console.log('%cError', estilo , error.message)
    }
  } );

  return apolloProvider;
}

/*
// Manually call this when user log in
export async function onLogin(apolloClient: any, token: string) {
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(AUTH_TOKEN, token)
  }
  // if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message)
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient: any) {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_TOKEN)
  }
  // if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
  }
}

*/
