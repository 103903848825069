
































































import { Component, Prop, Vue } from 'vue-property-decorator'

import { LayOutInterface } from '@/interfaces/LayOutInterface';
import { ClsCrudVue } from '@/components/crud/ClsCrudVue';

@Component
export default class btCrud extends Vue {
  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this )

  @Prop()
  private layout: LayOutInterface

  @Prop( {
    default: true
  } )
  private permitirIncluir: boolean

}
