import ClsApollo from "@/utils/ClsApollo"
import * as Bluebird from "bluebird"
import * as _ from 'lodash'
import { ClsCrudVue } from "../ClsCrudVue"

const SQL_REPOSICOES = "SELECT idClienteCurso, clicur.idCurso, cur.descricao AS curso, clicur.idCliente, cli.nome, clicur.idUsuario AS idInstrutor, usu.nome AS instrutor, inicio, termino, diaLetivo FROM clientescursos AS clicur INNER JOIN cursos AS cur ON clicur.idCurso = cur.idCurso INNER JOIN clientes AS cli ON clicur.idCliente = cli.idCliente INNER JOIN usuarios AS usu ON clicur.idUsuario = usu.idUsuario WHERE tipoAula = 'R' ORDER BY diaLetivo, cli.nome;"

export interface reposicoesInterface {
  idClienteCurso: number
  idCurso: number
  descricao: string
  idCliente: number
  nome: string
  idInstrutor: number
  instrutor: string
  inicio: string
  termino: string
  diaLetivo: number
  diaSemana: string
}

export default class ClsReposicao {

  private self: any
  private rsTmpReposicoes: Array<reposicoesInterface> = []

  public constructor( self: any ) {
    this.self = self
  }

  public get rsReposicoes (): Array<reposicoesInterface> {
    return this.rsTmpReposicoes
  }

  public pesquisarReposicoes (): Bluebird<boolean> {
    let clsApollo: ClsApollo = new ClsApollo()
    this.rsTmpReposicoes = []

    let clsCrudVue = new ClsCrudVue( this, 'ClsReposicao' )

    const sql = `
      query {
        listRaw(inputListRaw: {
          sql: "${SQL_REPOSICOES}"
        })
      }
    `

    return clsApollo
      .apolloQuery( this.self, sql, "listRaw" )
      .then( ( resultQuery: any ) => {

        _.forEach( resultQuery, ( valor: reposicoesInterface, chave: number ) => {

          let registro: reposicoesInterface = { ...valor }

          registro.inicio = registro.inicio.substring(0,5)
          registro.termino = registro.termino.substring(0,5)
          registro.diaSemana = <string>clsCrudVue.getDia( <number>registro.diaLetivo ).descricao,

            this.rsTmpReposicoes.push( registro )

        } )


        // dia: <string>this.clsCrudVue.getDia( <number>curso.diaLetivo ).descricao,
        return true
      } )

  }

}