import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";

import { LoginStore } from "./LoginStore";
import { AreaTransferenciaStore } from './AreaTransferenciaStore';
import { stateRootInterface } from '@/interfaces/StoreInterface';
import { PermissoesStore } from "./PermissoesStore";
import { CadastrosStore } from "./CadastrosStore";

Vue.use( Vuex );

const store: StoreOptions<stateRootInterface> = {
  modules: {
    login: LoginStore,
    permissoes: PermissoesStore,
    areaTransferencia: AreaTransferenciaStore,
    cadastros: CadastrosStore
  }
}

export default new Vuex.Store<stateRootInterface>( store );
