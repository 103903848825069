









































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import {
  LayOutInterface,
  statusCrudInterface,
} from '@/interfaces/LayOutInterface'

import MensagemForm from '@/includes/auxForms/MensagemForm.vue'
import ConfirmDialog from '@/includes/auxForms/ConfirmDialog.vue'
import Loading from '@/includes/auxForms/Loading.vue'
import ErroWs from '@/includes/auxForms/ErroWs.vue'

import crudPesquisa, { cabecalhoCrudPesquisaInterface } from '@/includes/auxForms/CrudPesquisa.vue'
import CrudDetalheSelect from '@/includes/auxForms/CrudDetalheSelect.vue'

import btCrud from '@/includes/components/btCrud.vue'

import * as _ from 'lodash'
import * as Bluebird from 'bluebird'
import Utils from '@/utils/Utils'
import ClsApollo from '@/utils/ClsApollo'
import { ICONES } from '@/includes/config/parametros'

import { ClsCrudVue } from '@/components/crud/ClsCrudVue'
import { ClienteAttributes, ClienteContatoAttributes, ClienteUsuarioAttributes, ClienteAcompanhamentoAttributes, ClienteEtiquetaAttributes, ClienteCursoAttributes } from '@/interfaces/backend/ModelAttributesInterface'
import Logger from '@/utils/Logger'

import ClienteCrudCadastro from './cliente.crud.cadastro.vue'

import store from '@/store'

// @ts-ignore
import { mask } from 'vue-the-mask'
import { TiposContatoType } from '@/includes/config/typeTiposContato'
import { stateRootInterface } from '../../../interfaces/StoreInterface'
import { emDESENVOLVIMENTO } from '../../../includes/config/setupDesenvolvimento'
import moment from 'moment'
import { DTFORMAT } from '../../../includes/config/DataTypesAplicacao'

@Component( {
  directives: {
    mask
  },
  components: {
    MensagemForm,
    Loading,
    ErroWs,
    ConfirmDialog,
    btCrud,
    crudPesquisa,
    CrudDetalheSelect,
    ClienteCrudCadastro
  },
} )

export default class Crud extends Vue {

  private rsClienteContatos: Array<ClienteContatoAttributes> = []
  private rsClienteCursos: Array<ClienteCursoAttributes> = []
  private rsClienteAcompanhamento: Array<ClienteAcompanhamentoAttributes> = []
  private rsClienteUsuarios: Array<ClienteUsuarioAttributes> = []
  private rsClienteEtiquetas: Array<ClienteEtiquetaAttributes> = []

  private idCanal: number = 0
  private descricaoAcompanhamento: string = ''

  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this, 'CadastroCliente' )

  private layout: LayOutInterface = this.clsCrudVue.layOutInicial

  private model: ClienteAttributes = this.resetModel()

  private refreshKeyForm: number = 0

  private created (): void {
    this.clsCrudVue.redirecionar( this, this.clsCrudVue.PERMISSOES.CLIENTE.PERMISSAO.CREATE )
  }

  private mounted (): void {
    this.resetInicial()
  }

  private resetModel (): ClienteAttributes {

    this.rsClienteContatos = []
    this.rsClienteCursos = []
    this.rsClienteUsuarios = []
    this.rsClienteEtiquetas = []
    this.rsClienteAcompanhamento = []

    let retorno: any = {}

    // TODO - RETIRAR EM PRODUCAO
    if ( emDESENVOLVIMENTO ) {
      this.rsClienteAcompanhamento = []
      this.rsClienteContatos = [{ descricao: '(37) 98811-8785', tipo: 'WhatsApp' }]
      this.rsClienteCursos = [{ idCurso: 1, diaLetivo: 1, idUsuario: 1, inicio: '08:00', termino: '09:00' }]
      this.idCanal = 1
      this.descricaoAcompanhamento = 'TESTE DE ACOMPANHAMENTO'

      retorno = {
        idCliente: 0,
        nome: 'Teste de Nome',
        genero: 'M',
        dataNascimento: '24/06/1974',
        bairro: "Centro",
        cidade: "Divinópolis",
        observacao: "Teste de Observacao",
        matricula: "00024525",
        internetcelular: true,
        computador: true,
        bandalarga: true,
        celular: true
      }
    } else {
      retorno = {
        idCliente: 0,
        idStatus: 1,
        nome: '',
        genero: '',
        dataNascimento: '',
        bairro: "",
        cidade: "",
        observacao: "",
        matricula: "",
        internetcelular: false,
        computador: false,
        bandalarga: false,
        celular: false
      }

      this.rsClienteAcompanhamento = []
      this.rsClienteContatos = []
      this.rsClienteCursos = []
      this.idCanal = -1
      this.descricaoAcompanhamento = ''

    }

    return retorno
  }

  private resetInicial (): void {
    this.layout.crud = this.clsCrudVue.StatusCrud.incluindo

    this.model = this.resetModel()

    this.refreshKeyForm++
  }

  private btCancel (): void {
    this.$router.push( '/Home' )
  }

  private onChangeRsClienteContatos ( contatos: Array<ClienteContatoAttributes> ): void {
    this.rsClienteContatos = contatos
  }

  private onChangeRsClienteCursos ( cursos: Array<ClienteCursoAttributes> ): void {
    this.rsClienteCursos = cursos
  }

  private onChangeRsClienteUsuarios ( rsClienteUsuarios: Array<ClienteUsuarioAttributes> ): void {
    this.rsClienteUsuarios = rsClienteUsuarios
  }

  private onChangeRsClienteEtiquetas ( rsClienteEtiquetas: Array<ClienteEtiquetaAttributes> ): void {
    this.rsClienteEtiquetas = rsClienteEtiquetas
  }

  private btConfirmarInclusaoEdicao (): void {

    let tmpModel: ClienteAttributes = { ... this.model }

    tmpModel.dataNascimento = Utils.converterDataParaBanco( <string>tmpModel.dataNascimento )

    if ( !tmpModel.dataNascimento ) delete tmpModel.dataNascimento

    //@ts-ignore
    if ( this.$refs.frmCadastro.validate() ) {

      this.rsClienteAcompanhamento.push( {
        descricao: this.descricaoAcompanhamento,
        idCanal: this.idCanal,
        idUsuario: this.$store.state.login.idUsuario,
      } )

      this.clsCrudVue.confirmarInclusaoEdicao( {

        nomeFrmCadastro: 'frmCadastro',
        model: tmpModel,
        fnPosMutationOK: this.resetInicial,
        campoChave: "idCliente",
        tabela: "Cliente",
        detalhe: [{
          modeloDetalhe: "ClienteContato",
          campoChaveAcrescentarDetalhe: "idCliente",
          dados: this.rsClienteContatos
        },
        {
          modeloDetalhe: "ClienteAcompanhamento",
          campoChaveAcrescentarDetalhe: "idCliente",
          dados: this.rsClienteAcompanhamento
        },
        {
          modeloDetalhe: "ClienteUsuario",
          campoChaveAcrescentarDetalhe: "idCliente",
          dados: Utils.transformarVetorEmObjetoRS( this.rsClienteUsuarios, 'idUsuario' )
        },
        {
          modeloDetalhe: "ClienteEtiqueta",
          campoChaveAcrescentarDetalhe: "idCliente",
          dados: Utils.transformarVetorEmObjetoRS( this.rsClienteEtiquetas, 'idEtiqueta' )
        },
        {
          modeloDetalhe: "ClienteCurso",
          campoChaveAcrescentarDetalhe: "idCliente",
          dados: this.rsClienteCursos
        }]
      } )
    }

  }

}
