




















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

// @ts-ignore
import { ClienteAttributes, ClienteContatoAttributes, ClienteUsuarioAttributes, ClienteAcompanhamentoAttributes } from '@/interfaces/backend/ModelAttributesInterface'

import { LayOutInterface } from '@/interfaces/LayOutInterface'
import { ClsCrudVue } from '../ClsCrudVue'

import Logger from '../../../utils/Logger'
import ClsApollo from '../../../utils/ClsApollo'
import Utils from '../../../utils/Utils'
import Bluebird from 'bluebird'

import _ from 'lodash'
import DataTableResponsive, { cabecalhoInterface } from '@/includes/auxForms/DataTableResponsive.vue'
import { DTFORMAT } from '../../../includes/config/DataTypesAplicacao'
import { GETTERS } from '../../../store/mutation-types'

interface listClienteAcompanhamentoIterface extends ClienteAcompanhamentoAttributes {
  canal: string
  usuario: string
}

@Component( { components: { DataTableResponsive } } )
export default class Crud extends Vue {

  private cabecalhos: Array<cabecalhoInterface> = [{
    text: 'Data',
    align: 'left',
    value: 'createdAt'
  },
  {
    text: 'Descrição',
    align: 'left',
    value: 'descricao'
  },
  {
    text: 'Usuario',
    align: 'left',
    value: 'usuario'
  },
  {
    text: 'Canal',
    align: 'left',
    value: 'canal'
  }]

  @Prop()
  //@ts-ignore
  private idCliente: number

  @Prop()
  //@ts-ignore
  private layout: LayOutInterface

  @Prop()
  //@ts-ignore
  private clsCrudVue: ClsCrudVue

  @Prop( {
    default: 1
  } )
  //@ts-ignore
  private keyRefresh: number

  private model: Array<listClienteAcompanhamentoIterface> = this.resetModel()

  @Watch( 'idCliente' )
  private onWatchIdCliente () {
    this.refresh()
  }

  @Watch( 'keyRefresh' )
  private onWatchKey () {
    this.refresh()
  }

  private mounted (): void {
    this.refresh()
  }

  private resetModel (): Array<listClienteAcompanhamentoIterface> {
    return []
  }

  private refresh () {
    if ( this.idCliente != 0 )
      this.obterDadosParaModel( this.idCliente )
  }

  private obterDadosParaModel ( idCliente: number ): Bluebird<any> {

    let clsApollo: ClsApollo = new ClsApollo()

    const sqlClienteAcompanhamento = `

      query {
        list(inputCrud: {
          tabela: "ClienteAcompanhamento"
          list: {
            attributes: ["idAcompanhamento","descricao","idCanal","idUsuario","createdAt"]
            where: {
              idCliente: ${idCliente}
            }
          }
        })
      }

    `

    this.model = this.resetModel()

    return clsApollo
      .apolloQuery( this, sqlClienteAcompanhamento, 'list' )
      .then( ( resultQuery: Array<ClienteAcompanhamentoAttributes> ) => {
        this.model = resultQuery.map( v => {
          return {
            createdAt: Utils.converterData( <string>v.createdAt, DTFORMAT.UTC, DTFORMAT.USUARIO_DATETIME ),
            descricao: v.descricao,
            canal: this.$store.getters[GETTERS.Canal]( v.idCanal ).descricao,
            usuario: this.$store.getters[GETTERS.Usuario]( v.idUsuario ).nome
          }
        } )
        // this.rsClienteUsuarios = rsClienteUsuarios.map( ( v: any ) => { return _.find( this.clsCrudVue.state.cadastros.rsUsuarios, { 'idUsuario': v.idUsuario } ) } )
      } )

  }

}

