import { Module } from "vuex";
import { stateRootInterface } from "@/interfaces/StoreInterface";
import { permissoesInterface } from "@/interfaces/backend/permissao.interfaces";
import { PERMISSOES_SETAR_MUTATION } from './mutation-types';

const getters = {

}

// ALTERAPERMISSAO - v1 - FRONTEND - Incluir Aqui a Permissao

const state: permissoesInterface = {

  CLIENTE: {
    EXIBEMENU: false,
    PERMISSAO: {
      CREATE: false,
      UPDATE: false,
      DELETE: false,
      ALTERARCANAL: false,
      EXCLUIRCONTATOS: false,
      ALTERARRESPONSAVEL: false,
    }
  },

  CURSO: {
    EXIBEMENU: false,
    PERMISSAO: {
      CREATE: false,
      UPDATE: false,
      DELETE: false,
    }
  },

  AGENDA: {
    EXIBEMENU: false,
    PERMISSAO: {
      CREATE: false,
      UPDATE: false,
      DELETE: false,
    }
  },

  CLIENTEACOMPANHAMENTO: {
    EXIBEMENU: false,
    PERMISSAO: {
      CREATE: false,
    }
  },

  USUARIO: {
    EXIBEMENU: false,
    PERMISSAO: {
      CREATE: false,
      UPDATE: false,
      DELETE: false,
      ALTERARSENHA: false,
    }
  },

  GRUPO: {
    EXIBEMENU: false,
    PERMISSAO: {
      CREATE: false,
      UPDATE: false,
      DELETE: false,
    }
  },

  GERAL: {
    EXIBEMENU: false,
    PERMISSAO: {
      TOTAL: false,
    }
  }


}

const mutations = {
  [PERMISSOES_SETAR_MUTATION] (
    stateAtual: permissoesInterface,
    payload: permissoesInterface
  ) {

    // ALTERAPERMISSAO - V1 - FRONTEND - Alterar Quando incluir nova Permissao do Sistema
    stateAtual.USUARIO = payload.USUARIO
    stateAtual.GERAL = payload.GERAL
    stateAtual.GRUPO = payload.GRUPO
    stateAtual.CLIENTE = payload.CLIENTE
    stateAtual.CLIENTEACOMPANHAMENTO = payload.CLIENTEACOMPANHAMENTO
    stateAtual.AGENDA = payload.AGENDA
    stateAtual.CURSO = payload.CURSO

  }
}

const actions = {
}

export const PermissoesStore: Module<permissoesInterface, stateRootInterface> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
