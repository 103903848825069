import ClsApollo from "@/utils/ClsApollo"
import * as Bluebird from "bluebird"
import * as _ from "lodash"

export interface rsListagemGeralInterface {
  idCliente: number
  idInstrutor: number
  idCurso: number
  nome: string
  matricula: string
  curso: string
  instrutor: string
  presente: boolean
  chamada: string
}

// const SQL_GERAL = 'SELECT DISTINCT ccur.tipoAula, ch.presente, ccur.idUsuario as idInstrutor, ccur.idCurso, cl.idCliente, cl.matricula, cl.nome, cur.descricao as curso, inst.nome AS instrutor FROM clientes AS cl LEFT JOIN clientescursos AS ccur ON cl.idCliente = ccur.idCliente LEFT JOIN cursos AS cur ON ccur.idCurso = cur.idCurso LEFT JOIN usuarios AS inst ON ccur.idUsuario = inst.idUsuario WHERE ccur.diaLetivo = :DIALETIVO AND ccur.inicio = :HRINICIO AND ccur.termino = :HRTERMINO;'
const SQL_GERAL = 'SELECT DISTINCT ccur.tipoAula, ch.presente, ccur.idUsuario as idInstrutor, ccur.idCurso, cl.idCliente, cl.matricula, cl.nome, cur.descricao as curso, inst.nome AS instrutor FROM clientes AS cl LEFT JOIN clientescursos AS ccur ON cl.idCliente = ccur.idCliente LEFT JOIN cursos AS cur ON ccur.idCurso = cur.idCurso LEFT JOIN usuarios AS inst ON ccur.idUsuario = inst.idUsuario LEFT JOIN chamadas AS ch ON cl.idCliente = ch.idCliente AND ch.inicio = :HRINICIO AND DATA = :DATA WHERE ccur.diaLetivo = :DIALETIVO AND ccur.inicio = :HRINICIO AND ccur.termino = :HRTERMINO;'

const CABECALHO_GERAL: Array<any> = [{
  text: 'Nome',
  align: 'left',
  value: 'nome'
},
{
  text: 'Matricula',
  align: 'left',
  value: 'matricula'
},
{
  text: 'Curso',
  align: 'left',
  value: 'curso'
},
{
  text: 'Instrutor',
  align: 'left',
  value: 'instrutor'
  }, {
    text: 'Tipo Aula',
    align: 'left',
    value: 'tipoAula'
  },
  {
  text: 'Presente',
  align: 'left',
  value: 'chamada'
}]

//const SQL_POR_CATEGORIA = 'SELECT DISTINCT ccur.tipoAula, ch.presente, ccur.idUsuario as idInstrutor, ccur.idCurso, cl.idCliente, cl.matricula, cl.nome, cur.descricao as curso, inst.nome AS instrutor FROM clientes AS cl LEFT JOIN clientescursos AS ccur ON cl.idCliente = ccur.idCliente LEFT JOIN cursos AS cur ON ccur.idCurso = cur.idCurso LEFT JOIN usuarios AS inst ON ccur.idUsuario = inst.idUsuario LEFT JOIN tblcursoscategorias AS cat ON cur.idCursoCategoria= cat.idCursoCategoria WHERE ccur.diaLetivo = :DIALETIVO AND ccur.inicio = :HRINICIO AND ccur.termino = :HRTERMINO AND cat.descricao = :DESCRICAOCATEGORIA;'
const SQL_POR_CATEGORIA = 'SELECT DISTINCT ccur.tipoAula, ch.presente, ccur.idUsuario as idInstrutor, ccur.idCurso, cl.idCliente, cl.matricula, cl.nome, cur.descricao as curso, inst.nome AS instrutor FROM clientes AS cl LEFT JOIN clientescursos AS ccur ON cl.idCliente = ccur.idCliente LEFT JOIN cursos AS cur ON ccur.idCurso = cur.idCurso LEFT JOIN usuarios AS inst ON ccur.idUsuario = inst.idUsuario LEFT JOIN tblcursoscategorias AS cat ON cur.idCursoCategoria= cat.idCursoCategoria LEFT JOIN chamadas AS ch ON cl.idCliente = ch.idCliente AND ch.inicio = :HRINICIO AND DATA = :DATA WHERE ccur.diaLetivo = :DIALETIVO AND ccur.inicio = :HRINICIO AND ccur.termino = :HRTERMINO AND cat.descricao = :DESCRICAOCATEGORIA;'

//const SQL_POR_INSTRUTOR = 'SELECT DISTINCT ccur.tipoAula, ch.presente, ccur.idUsuario as idInstrutor, ccur.idCurso, cl.idCliente, cl.matricula, cl.nome, cur.descricao as curso, inst.nome AS instrutor FROM clientes AS cl LEFT JOIN clientescursos AS ccur ON cl.idCliente = ccur.idCliente LEFT JOIN cursos AS cur ON ccur.idCurso = cur.idCurso LEFT JOIN usuarios AS inst ON ccur.idUsuario = inst.idUsuario WHERE ccur.diaLetivo = :DIALETIVO AND ccur.inicio = :HRINICIO AND ccur.termino = :HRTERMINO AND inst.nome = :NOMEINSTRUTOR;'
const SQL_POR_INSTRUTOR = 'SELECT DISTINCT ccur.tipoAula, ch.presente, ccur.idUsuario as idInstrutor, ccur.idCurso, cl.idCliente, cl.matricula, cl.nome, cur.descricao as curso, inst.nome AS instrutor FROM clientes AS cl LEFT JOIN clientescursos AS ccur ON cl.idCliente = ccur.idCliente LEFT JOIN cursos AS cur ON ccur.idCurso = cur.idCurso LEFT JOIN usuarios AS inst ON ccur.idUsuario = inst.idUsuario LEFT JOIN chamadas AS ch ON cl.idCliente = ch.idCliente AND ch.inicio = :HRINICIO AND DATA = :DATA WHERE ccur.diaLetivo = :DIALETIVO AND ccur.inicio = :HRINICIO AND ccur.termino = :HRTERMINO AND inst.nome = :NOMEINSTRUTOR;'

const SQL_LISTAGEM = [SQL_GERAL, SQL_POR_CATEGORIA, SQL_POR_INSTRUTOR]

const FORMATOS_LISTAGEM = ['Geral', 'Categoria', 'Instrutores']

export default class ClsQuadroListagemAlunos {

  private self: any

  private tmpTitulo: string = ''

  private tmpItens: Array<any> = []

  public constructor( self: any ) {
    this.self = self
  }

  public refresh ( evento: any, tipo: string ): Bluebird<boolean> {

    this.tmpTitulo = 'Listagem Alunos - '.concat( tipo ).concat( " - " ).concat( evento.event.name ).concat( " | " ).concat( evento.event.start.substring( 11 ) ).concat( "-" ).concat( evento.event.end.substring( 11 ) )

    let indice: number = _.findIndex( FORMATOS_LISTAGEM, ( o ) => o == tipo )

    if ( indice >= 0 ) {

      let clsApollo: ClsApollo = new ClsApollo()

      const sql = `
        query {
          listRaw(inputListRaw: {
            sql: "${SQL_LISTAGEM[indice]}",
            replacements: {
              DATA: "${evento.event.start.substring( 0, 10 )}"
              DIALETIVO: ${evento.day.weekday}
              HRINICIO: "${evento.event.start.substring( 11 )}"
              HRTERMINO: "${evento.event.end.substring( 11 )}"
              DESCRICAOCATEGORIA: "${evento.event.name.substring( 0, evento.event.name.indexOf( ":" ) ).trim()}"
              NOMEINSTRUTOR: "${evento.event.name.substring( 0, evento.event.name.indexOf( ":" ) ).trim()}"
            }
          })
        }
        `

      return clsApollo
        .apolloQuery( this.self, sql, "listRaw", false, "no-cache", false )
        .then( ( resultQuery: Array<rsListagemGeralInterface> ) => {
          this.tmpItens = resultQuery

          _.forEach( this.tmpItens, ( chamada: rsListagemGeralInterface, indice: number ) => {
            let retorno: string = ""
            if ( chamada.presente == null ) {
              retorno = "PENDENTE"
            } else {
              retorno = chamada.presente ? "Sim" : "Não"
            }
            this.tmpItens[indice].chamada = retorno
          } )

          return true
        } ).catch( err => {
          return false
        } )

    } else {
      this.tmpItens = []
      return Bluebird.resolve( false )
    }

  }

  public get cabecalho (): Array<any> {
    return CABECALHO_GERAL
  }

  public get itens (): Array<rsListagemGeralInterface> {
    return this.tmpItens
  }

  public get titulo (): string {
    return this.tmpTitulo
  }

}