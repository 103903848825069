























































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { LayOutInterface } from '../../../interfaces/LayOutInterface'
import { ClsCrudVue } from '../ClsCrudVue'
import { TiposContatoType } from '../../../includes/config/typeTiposContato'
import { ClienteContatoAttributes, ClienteCursoAttributes } from '../../../interfaces/backend/ModelAttributesInterface'

import * as _ from 'lodash'

import DataTableResponsive, { cabecalhoInterface, acoesInterface } from '@/includes/auxForms/DataTableResponsive.vue'

// @ts-ignore
import { mask } from 'vue-the-mask'
import Logger from '../../../utils/Logger'
import Utils from '../../../utils/Utils'
import moment, { Moment } from 'moment'

export interface ClienteCursoExibicao {
  curso: string
  instrutor: string
  dia: string
  hrInicio: string
  hrTermino: string
  tipoAula: string
}

@Component( {
  directives: {
    mask
  },
  components: {
    DataTableResponsive
  }
} )
export default class Crud extends Vue {

  private clsCrudVue: ClsCrudVue = new ClsCrudVue( this, 'CrudHorario' )

  @Prop()
  //@ts-ignore
  private rsClienteCursos: Array<ClienteCursoAttributes>

  @Prop()
  //@ts-ignore
  private layout: LayOutInterface

  private model: ClienteCursoAttributes = this.resetModel()

  private resetModel (): ClienteCursoAttributes {
    return {
      idCurso: 0,
      idUsuario: 0,
      diaLetivo: 0,
      inicio: '',
      termino: '',
      tipoAula: ''
    }
  }

  private get rsClienteCursosExibicao (): Array<ClienteCursoExibicao> {

    let retorno: Array<ClienteCursoExibicao> = []

    _.forEach( this.rsClienteCursos, ( curso: ClienteCursoAttributes, indice: number ) => {
      retorno.push( {
        curso: <string>this.clsCrudVue.getCurso( <number>curso.idCurso ).descricao,
        instrutor: <string>this.clsCrudVue.getUsuario( <number>curso.idUsuario ).nome,
        dia: <string>this.clsCrudVue.getDia( <number>curso.diaLetivo ).descricao,
        hrInicio: <any>curso.inicio,
        hrTermino: <any>curso.termino,
        tipoAula: <string>curso.tipoAula
      } )
    } )

    return retorno
  }

  // Tudo vazio ou preenchido....
  private keyRefresh: number = 0
  private habilitarIncluir: boolean = false

  private validarCurso (): boolean | string {

    let retorno: boolean | string = Utils.objetoAllPropsNulasOuVazias( this.model )
    this.habilitarIncluir = false

    if ( !retorno ) {
      retorno = Utils.objetoAllPropsNaoVazias( this.model )

      if ( retorno ) {

        let inicio: Moment = moment( this.model.inicio, 'HH:mm' )
        let termino: Moment = moment( this.model.termino, 'HH:mm' )

        retorno = termino.diff( inicio, 'hours' ) >= 1

        if ( !retorno ) {
          retorno = 'Hora Inicial deve ser menor que hora final. Mínimo de 1h'
          this.habilitarIncluir = false
        } else {
          this.habilitarIncluir = true
        }

      } else {
        retorno = 'Preencha todos os campos.'
      }

    }

    // this.keyRefresh++

    return retorno
  }

  private btEditarCurso ( clienteCurso: ClienteCursoExibicao, indice: number ) {

    let tmp: Array<ClienteCursoAttributes> = [...this.rsClienteCursos]

    this.model = { ...tmp[indice] }

    tmp.splice( indice, 1 )

    this.$emit( 'onChange', tmp )

  }

  private btIncluirCurso () {

    if ( _.findIndex( this.rsClienteCursos, this.model ) < 0 ) {
      this.$emit( 'onChange', [...this.rsClienteCursos, this.model] )
    }

    this.model = this.resetModel()
  }

  private btCancelarCurso () {
    this.model = this.resetModel()
    this.keyRefresh++
  }

}

